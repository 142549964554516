import React, { useContext, useState } from 'react';

export interface SettingsContextType {
    apiBaseUrl: string;
    apiKey: string;
    ui: {
        drawerWidth: number;
    };
}

let initialState: SettingsContextType = {
    apiBaseUrl:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000/api/v1/'
            : process.env.REACT_APP_VALID8_API_URL!,
    apiKey: process.env.REACT_APP_VALID8_API_KEY!,
    ui: {
        drawerWidth: 240,
    },
};

const SettingsContext = React.createContext<SettingsContextType>(initialState);

const SettingsProvider = (props: any) => {
    const [state, setState] = useState(initialState);

    return <SettingsContext.Provider value={state}>{props.children}</SettingsContext.Provider>;
};

const useSettings = () => useContext(SettingsContext);

export { SettingsContext, SettingsProvider, useSettings };
