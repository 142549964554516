import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import countryList from 'country-list';
import { useState } from 'react';
import { IAddress, IAddressSchema } from '../../hooks/useUsers';
import { sortStringArray } from '../../libs/common';

const UserDetailsAddressesAddForm = (props: {
    handleAddAddress: (formDataAddress: IAddress) => void;
    handleCancelAddAddressForm: () => void;
    visible: boolean;
}) => {
    const { handleAddAddress, handleCancelAddAddressForm, visible } = props;
    const [formDataAddress, setFormDataAddress] = useState<IAddress>({} as IAddress);

    const { error } = IAddressSchema.validate(formDataAddress, {
        allowUnknown: true,
    });

    return (
        <Paper elevation={3} sx={{ p: 4, mb: 4, display: visible ? 'block' : 'none' }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">Add Address</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="address1"
                        label="Address 1"
                        value={formDataAddress.street1}
                        onChange={(e) =>
                            setFormDataAddress({ ...formDataAddress, street1: e.target.value })
                        }
                        error={
                            !!error?.details.find((d) => d.context && d.context.key === 'address1')
                        }
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'address1')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="address2"
                        label="Address 2"
                        value={formDataAddress.street2}
                        onChange={(e) =>
                            setFormDataAddress({ ...formDataAddress, street2: e.target.value })
                        }
                        error={
                            !!error?.details.find((d) => d.context && d.context.key === 'address2')
                        }
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'address2')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="city"
                        label="City"
                        value={formDataAddress.city}
                        onChange={(e) =>
                            setFormDataAddress({ ...formDataAddress, city: e.target.value })
                        }
                        error={!!error?.details.find((d) => d.context && d.context.key === 'city')}
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'city')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="state"
                        label="State"
                        value={formDataAddress.state}
                        onChange={(e) =>
                            setFormDataAddress({ ...formDataAddress, state: e.target.value })
                        }
                        error={!!error?.details.find((d) => d.context && d.context.key === 'state')}
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'state')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="zipCode"
                        label="Zip Code"
                        value={formDataAddress.zipCode}
                        onChange={(e) =>
                            setFormDataAddress({ ...formDataAddress, zipCode: e.target.value })
                        }
                        error={
                            !!error?.details.find((d) => d.context && d.context.key === 'zipCode')
                        }
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'zipCode')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="country-selector-label">Country</InputLabel>
                        <Select
                            name="country"
                            label="Country"
                            value={formDataAddress.country}
                            onChange={(e) =>
                                setFormDataAddress({ ...formDataAddress, country: e.target.value })
                            }
                            fullWidth
                        >
                            {sortStringArray(countryList.getNames()).map((countryName) => (
                                <MenuItem
                                    key={countryList.getCode(countryName)}
                                    value={countryList.getCode(countryName)}
                                >
                                    {countryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="contained"
                        disabled={error && error.details.length > 0}
                        onClick={() => {
                            handleAddAddress(formDataAddress);
                        }}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            handleCancelAddAddressForm();
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserDetailsAddressesAddForm;
