import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

export type WorkerStatus = {
    protocol: string;
    ip: string;
    port: number;
    path: string;
    hostname: string;
    connected: number;
    lastUpdate: number;
};

/**
 * Fetches email lookup stats from the API
 * @param batchId Batch ID
 * @returns Normalized email lookup stats
 */
const useValid8Workers = () => {
    const settings = useSettings();
    const user = useUser();

    const fetchEmailLookupHistory = async (): Promise<WorkerStatus[]> => {
        return axios
            .get(`${settings.apiBaseUrl}workers/list`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                return response.data as WorkerStatus[];
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    return useQuery(['workersList'], fetchEmailLookupHistory, {
        enabled: !user.isLoggingIn && user.token && user.me && user.me.email ? true : false,
        refetchOnWindowFocus: false,
    });
};

export default useValid8Workers;
