import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DialogProvider } from './hooks/useDialog';
import { SettingsProvider } from './hooks/useSettings';
import { UserProvider } from './hooks/useUser';
import { Valid8Provider } from './hooks/useValid8';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE || '');

// Customise the Material UI theme
// https://material-ui.com/customization/theming/
const theme = createTheme({
    palette: {
        primary: {
            main: '#00838F',
        },
        secondary: {
            main: '#FFECB3',
        },
        error: {
            main: '#F5501D',
        },
        warning: {
            main: '#FFB300',
        },
        success: {
            main: '#329F5E',
        },
        info: {
            main: '#4285f4',
        },
        background: {
            default: '#fff',
        },
        text: {
            secondary: '#424242',
        },
    },
    typography: {
        fontFamily: ['PT Sans', 'Roboto', 'sans-serif'].join(','),
    },
});

// Create a client
const queryClient = new QueryClient();

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <DialogProvider>
                <QueryClientProvider client={queryClient}>
                    <SettingsProvider>
                        <UserProvider>
                            <Valid8Provider>
                                <App />
                            </Valid8Provider>
                        </UserProvider>
                    </SettingsProvider>
                </QueryClientProvider>
            </DialogProvider>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
