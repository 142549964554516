/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface AlertDialogState {
    title: string;
    message: string;
}

interface ConfirmDialogState {
    title: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
}

interface DialogContextProps {
    alert: (dialogState: AlertDialogState) => Promise<void>;
    confirm: (dialogState: ConfirmDialogState) => Promise<void>;
}

const DialogContext = createContext<DialogContextProps>({
    alert: () => new Promise(() => {}),
    confirm: () => new Promise(() => {}),
});

interface DialogProviderProps {
    children: React.ReactNode;
}

const DialogProvider = ({ children }: DialogProviderProps) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>({
        title: '',
        message: '',
    });
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [resolveAlertFunction, setResolveAlertFunction] = useState<() => void>(() => {});
    const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogState>({
        title: '',
        message: '',
    });
    const [resolveConfirmFunction, setResolveConfirmFunction] = useState<() => void>(() => {});
    const [rejectConfirmFunction, setRejectConfirmFunction] = useState<() => void>(() => {});

    // Alert Dialog
    const alert = (newDialogState: AlertDialogState) => {
        setAlertDialogState(newDialogState);
        setIsAlertOpen(true);

        return new Promise<void>((resolve) => {
            setResolveAlertFunction(() => () => {
                setIsAlertOpen(false);
                resolve();
            });
        });
    };

    const closeAlertDialog = () => {
        resolveAlertFunction();
    };

    // Confirm Dialog
    const confirm = (newDialogState: ConfirmDialogState) => {
        setConfirmDialogState(newDialogState);
        setIsConfirmOpen(true);

        return new Promise<void>((resolve, reject) => {
            setResolveConfirmFunction(() => () => {
                setIsConfirmOpen(false);
                resolve();
            });

            setRejectConfirmFunction(() => () => {
                setIsConfirmOpen(false);
                reject();
            });
        });
    };

    const closeConfirmDialog = () => {
        resolveConfirmFunction();
    };

    const cancelConfirmDialog = () => {
        rejectConfirmFunction();
    };

    return (
        <DialogContext.Provider value={{ alert, confirm }}>
            {children}

            <Dialog open={isAlertOpen} onClose={closeAlertDialog} maxWidth='sm' fullWidth>
                <DialogTitle>{alertDialogState.title}</DialogTitle>
                <DialogContent>{alertDialogState.message}</DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='primary'
                        autoFocus
                        onClick={closeAlertDialog}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isConfirmOpen} onClose={closeAlertDialog} maxWidth='sm' fullWidth>
                <DialogTitle>{confirmDialogState.title}</DialogTitle>
                <DialogContent>{confirmDialogState.message}</DialogContent>
                <DialogActions>
                    <Button variant='contained' color='secondary' onClick={cancelConfirmDialog}>
                        {confirmDialogState.cancelButtonText || 'Cancel'}
                    </Button>
                    <Button variant='contained' color='primary' onClick={closeConfirmDialog}>
                        {confirmDialogState.confirmButtonText || 'YES'}
                    </Button>
                </DialogActions>
            </Dialog>
        </DialogContext.Provider>
    );
};

const useDialog = () => useContext(DialogContext);

export { DialogProvider, useDialog };
