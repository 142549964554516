import { Button, useTheme } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridSortModel,
    useGridApiRef,
} from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { objectIdToDate } from '../../libs/common';
import {
    EmailTestResult,
    EmailTestResultsHistory,
    emailLookupResultToHumanReadable,
} from '../../types/testEmail';
import RawLogDialog from './RawLogDialog';

interface Props {
    data: EmailTestResultsHistory | undefined;
    isLoading: boolean;
    sortModel: GridSortModel;
    setSortModel: (sortModel: GridSortModel) => void;
    paginationModel: { page: number; pageSize: number };
    setPaginationModel: (paginationModel: { page: number; pageSize: number }) => void;
    maximumRows?: number;
}

const TestEmailResultsGrid = (props: Props) => {
    const theme = useTheme();
    const user = useUser();
    const {
        data,
        isLoading,
        sortModel,
        setSortModel,
        paginationModel,
        setPaginationModel,
        maximumRows,
    } = props;

    // Log dialog hooks
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [rawLog, setRawLog] = useState<{ userId: string; jobId: string } | null>(null);

    const userIsAdmin = user && user.me && user.me.role === 'admin' ? true : false;

    const apiRef = useGridApiRef();
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        rawLog: userIsAdmin,
    });

    useEffect(() => {
        setColumnVisibilityModel((prev) =>
            prev.rawLog !== userIsAdmin ? { ...prev, rawLog: userIsAdmin } : prev,
        );

        // Update the column visibility using apiRef
        apiRef.current.setColumnVisibility('rawLog', userIsAdmin ? true : false);
    }, [apiRef, userIsAdmin]);

    const columns = useMemo<GridColDef<EmailTestResult>[]>(() => {
        return [
            {
                field: 'batchId',
                headerName: 'Batch ID',
                width: 200,
                sortable: maximumRows ? false : true,
            },
            {
                field: 'jobId',
                headerName: 'Job ID',
                width: 200,
                sortable: maximumRows ? false : true,
                valueGetter: (params) => objectIdToDate(params.row.jobId),
                sortComparator: (v1, v2) => {
                    const date1 = moment(v1);
                    const date2 = moment(v2);
                    return date1.diff(date2);
                },
                renderCell: (params) => moment(params.value).format('L hh:mm a'),
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                sortable: maximumRows ? false : true,
            },
            {
                field: 'result',
                headerName: 'Result',
                width: 200,
                sortable: maximumRows ? false : true,
                valueGetter: (params) => emailLookupResultToHumanReadable(params.row.result),
            },
            {
                field: 'rawLog',
                headerName: 'Raw Log',
                headerAlign: 'center',
                align: 'center',
                width: 100,
                sortable: false,
                renderCell: (params: any) => {
                    return (
                        <Button
                            size="small"
                            onClick={() => {
                                setLogDialogOpen(true);
                                setRawLog({
                                    userId: '',
                                    jobId: params.row.jobId,
                                });
                            }}
                        >
                            View Log
                        </Button>
                    );
                },
            },
        ];
    }, [maximumRows]);

    return (
        <>
            <DataGrid
                apiRef={apiRef}
                rows={data ? data.results : []}
                getRowId={(row) => row.jobId}
                rowCount={data ? data.total : 0}
                columns={columns}
                initialState={{
                    // pagination: { paginationModel: { pageSize: 10 } },
                    columns: {
                        columnVisibilityModel,
                    },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode="server"
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                    console.log(newSortModel);
                    setSortModel(newSortModel);
                }}
                sortingMode="server"
                loading={isLoading && !data}
                disableRowSelectionOnClick
                disableColumnMenu
                hideFooter={maximumRows ? true : false}
                sx={{
                    marginTop: theme.spacing(2),
                }}
            />

            {user && user.me && user.me.role === 'admin' && rawLog && (
                <RawLogDialog
                    userId={user.me && user.me._id ? user.me._id : ''}
                    jobId={rawLog.jobId}
                    open={logDialogOpen}
                    setOpen={setLogDialogOpen}
                />
            )}
        </>
    );
};

export default TestEmailResultsGrid;
