import { LoadingButton } from '@mui/lab';
import { Alert, Box, SelectChangeEvent, TextField } from '@mui/material';
import { useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useUser } from '../../hooks/useUser';
import { isValidEmail } from '../../libs/common';

interface FormData {
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
}

const Register = () => {
    const user = useUser();
    const [formData, setFormData] = useState<FormData>({});
    const [repearNewPassword, setRepearNewPassword] = useState<string>('');

    const isPasswordValid: boolean = formData.password === repearNewPassword;

    /**
     * Handle input change
     * @param event
     */
    const handleInputChange = (
        event:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
            | SelectChangeEvent<string>,
    ) => {
        const { name, value } = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        if (!formData.email || !formData.password || !formData.firstName || !formData.lastName) {
            return;
        }

        user.signup(formData.email, formData.password, formData.firstName, formData.lastName);
    };

    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                onChange={handleInputChange}
                error={!formData.firstName || !formData.firstName.length}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                onChange={handleInputChange}
                error={!formData.lastName || !formData.lastName.length}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleInputChange}
                error={!isValidEmail(formData.email || '')}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
                error={!formData.password || !formData.password.length}
            />
            <PasswordStrengthBar password={formData.password} />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passwordConfirm"
                label="Repeat Password"
                type="password"
                id="passwordConfirm"
                onChange={(e) => setRepearNewPassword(e.target.value)}
                error={!isPasswordValid}
            />
            <Alert severity="error" sx={{ mt: 2, display: user.signUpError ? 'block' : 'none' }}>
                {user.signUpError}
            </Alert>
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleFormSubmit}
                loading={user.isSigningUp}
                disabled={
                    !isPasswordValid || !formData.email || !formData.firstName || !formData.lastName
                }
            >
                Sign Up
            </LoadingButton>
        </Box>
    );
};

export default Register;
