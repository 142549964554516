import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSettings } from './useSettings';

interface UserContextType {
    maintenanceMode: boolean;
    token: string | null;
    isFetchingMe: boolean;
    me: any | null;
    login: (email: string, password: string) => Promise<string | null>;
    isLoggingIn: boolean;
    loginError: string | null;
    logout: () => void;
    signup: (
        email: string,
        password: string,
        firstName: string,
        lastName: string,
    ) => Promise<string | null>;
    isSigningUp: boolean;
    signUpError: string | null;
    updateAvailableCredit: (availableCredit: number) => void;
}

let initialState: UserContextType = {
    maintenanceMode: false,
    token: null,
    isFetchingMe: false,
    me: null,
    login: (email: string, password: string) => new Promise(() => {}),
    isLoggingIn: false,
    loginError: null,
    logout: () => {},
    signup: (email: string, password: string, firstName: string, lastName: string) =>
        new Promise(() => {}),
    isSigningUp: false,
    signUpError: null,
    updateAvailableCredit: (availableCredit: number) => {},
};

const UserContext = React.createContext<UserContextType>(initialState);

const UserProvider = ({ children }: any) => {
    const settings = useSettings();
    const [state, setState] = useState(initialState);
    const [hasMounted, setHasMounted] = useState(false);

    /**
     * Fetch user data
     */
    const fetchMe = useCallback(
        (token: string | null = null) => {
            if (!state.token && !token) {
                return;
            }

            setState((oldValues) => {
                return { ...oldValues, isFetchingMe: true };
            });

            axios
                .get(settings.apiBaseUrl + 'auth/me', {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': settings.apiKey,
                        Authorization: `Bearer ${token || state.token}`,
                    },
                })
                .then(async (response: any) => {
                    if (response.data) {
                        setState((oldValues) => {
                            return {
                                ...oldValues,
                                me: response.data,
                                isFetchingMe: false,
                                maintenanceMode: false,
                            };
                        });
                    } else {
                        setState((oldValues) => {
                            return {
                                ...oldValues,
                                me: null,
                                isFetchingMe: false,
                                maintenanceMode: false,
                            };
                        });
                    }
                })
                .catch((err) => {
                    if (err.code === 'ERR_NETWORK') {
                        setState((oldValues) => {
                            return {
                                ...oldValues,
                                me: null,
                                isFetchingMe: false,
                                maintenanceMode: true,
                            };
                        });
                    } else {
                        setState((oldValues) => {
                            return {
                                ...oldValues,
                                me: null,
                                isFetchingMe: false,
                                maintenanceMode: false,
                            };
                        });

                        if (err && err.response && err.response.status === 401) {
                        } else {
                            console.error(err);
                        }
                    }
                });
        },
        [settings.apiBaseUrl, settings.apiKey, state.token],
    );

    /**
     * Verify user token
     */
    const verifyUser = useCallback(async () => {
        await axios
            .post(settings.apiBaseUrl + 'auth/refreshToken', undefined, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                },
            })
            .then(async (response: any) => {
                if (response.data.token) {
                    setState((oldValues) => {
                        // Refresh token every 5 minutes
                        // setTimeout(verifyUser, 5 * 60 * 1000);

                        return { ...oldValues, token: response.data.token };
                    });
                } else {
                    setState((oldValues) => {
                        return { ...oldValues, token: null };
                    });
                }
            })
            .catch((err) => {
                if (err.code === 'ERR_NETWORK') {
                    setState((oldValues) => {
                        return {
                            ...oldValues,
                            token: null,
                            me: null,
                            loginError: 'Network error',
                            maintenanceMode: true,
                        };
                    });
                } else if (err && err.response && err.response.status === 401) {
                    setState((oldValues) => {
                        return {
                            ...oldValues,
                            token: null,
                            me: null,
                            loginError: 'Unauthorized',
                            maintenanceMode: false,
                        };
                    });
                } else {
                    console.error(err);
                    setState((oldValues) => {
                        return {
                            ...oldValues,
                            token: null,
                            me: null,
                            loginError: err.message || 'Unknown error',
                            maintenanceMode: false,
                        };
                    });
                }
            });
    }, [settings.apiBaseUrl, settings.apiKey]);

    /**
     * Verify user token on mount
     */
    useEffect(() => {
        if (state.token) {
            return;
        }

        if (hasMounted) {
            return;
        }

        verifyUser().then(() => {
            setHasMounted(true);
        });
    }, [hasMounted, state.token, verifyUser]);

    /**
     * Fetch user data on token change or login if no user data is present
     * This is used to fetch user data when the user refreshes the page
     */
    useEffect(() => {
        if (state.token && !state.me) {
            fetchMe();
        }
    }, [fetchMe, state.me, state.token]);

    /**
     * Login user
     */
    const login = useCallback(
        (email: string, password: string) => {
            return new Promise<string>((resolve, reject) => {
                setState((oldValues) => {
                    return { ...oldValues, isLoggingIn: true, loginError: null };
                });

                axios
                    .post(
                        settings.apiBaseUrl + 'auth/login',
                        { email, password },
                        {
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': settings.apiKey,
                            },
                        },
                    )
                    .then((response: any) => {
                        if (response.data.token) {
                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isLoggingIn: false,
                                    loginError: null,
                                    token: response.data.token,
                                    maintenanceMode: false,
                                };
                            });

                            if (response.data.token) {
                                // Fetch user data
                                fetchMe();
                            }

                            // Refresh token every 5 minutes
                            setTimeout(verifyUser, 5 * 60 * 1000);

                            resolve(response.data.token);
                        } else {
                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isLoggingIn: false,
                                    loginError: 'Could not login. Unknown error occured.',
                                    token: null,
                                    me: null,
                                    maintenanceMode: false,
                                };
                            });

                            reject('Could not login. Unknown error occured.');
                        }
                    })
                    .catch((error) => {
                        if (error.code === 'ERR_NETWORK') {
                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isLoggingIn: false,
                                    loginError: 'Network error occurred.',
                                    token: null,
                                    me: null,
                                    maintenanceMode: true,
                                };
                            });

                            reject('Network error occurred.');
                        } else {
                            if (error && error.response) {
                                if (error.response.status === 400) {
                                    setState((oldValues) => {
                                        return {
                                            ...oldValues,
                                            isLoggingIn: false,
                                            loginError: 'Please fill all the fields correctly!',
                                            token: null,
                                            me: null,
                                            maintenanceMode: false,
                                        };
                                    });

                                    reject('Please fill all the fields correctly!');
                                } else if (error.response.status === 401) {
                                    setState((oldValues) => {
                                        return {
                                            ...oldValues,
                                            isLoggingIn: false,
                                            loginError: 'Invalid email and password combination.',
                                            token: null,
                                            me: null,
                                            maintenanceMode: false,
                                        };
                                    });

                                    reject('Invalid email and password combination.');
                                } else {
                                    console.log(error.response);

                                    setState((oldValues) => {
                                        return {
                                            ...oldValues,
                                            isLoggingIn: false,
                                            loginError: 'Unknown error occurred.',
                                            token: null,
                                            me: null,
                                            maintenanceMode: false,
                                        };
                                    });

                                    reject('Unknown error occurred.');
                                }
                            } else {
                                console.error(error);

                                console.log(error.response);

                                setState((oldValues) => {
                                    return {
                                        ...oldValues,
                                        isLoggingIn: false,
                                        loginError: error.message || 'Unknown error occurred.',
                                        token: null,
                                        me: null,
                                        maintenanceMode: false,
                                    };
                                });

                                reject(error.message || 'Unknown error occurred.');
                            }
                        }
                    });
            });
        },
        [fetchMe, settings.apiBaseUrl, settings.apiKey, verifyUser],
    );

    /**
     * Logout user
     */
    const logout = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            axios
                .post(settings.apiBaseUrl + 'auth/logout', undefined, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': settings.apiKey,
                        Authorization: `Bearer ${state.token}`,
                    },
                })
                .then(async (response) => {
                    setState(initialState);

                    window.localStorage.setItem('logout', Date.now().toString());

                    resolve();
                })
                .catch((error) => {
                    if (error.code === 'ERR_NETWORK') {
                        setState((oldValues) => {
                            return {
                                ...oldValues,
                                maintenanceMode: true,
                            };
                        });
                    } else {
                        console.error(error);

                        reject();
                    }
                });
        });
    }, [settings.apiBaseUrl, settings.apiKey, state.token]);

    /**
     * Sync logout across tabs
     */
    const syncLogout = useCallback((event: StorageEvent): any => {
        if (event.key === 'logout') {
            // If using react-router-dom, you may call history.push("/")
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('storage', syncLogout);
        return () => {
            window.removeEventListener('storage', syncLogout);
        };
    }, [syncLogout]);

    /**
     * Signup new user
     * @param email email address
     * @param password password
     * @param firstName first name
     * @param lastName last name
     */
    const signup = useCallback(
        (email: string, password: string, firstName: string, lastName: string) => {
            return new Promise<string>((resolve, reject) => {
                setState((oldValues) => {
                    return { ...oldValues, isSigningUp: true, signUpError: null };
                });

                axios
                    .post(
                        settings.apiBaseUrl + 'auth/signup',
                        { firstName, lastName, email, password },
                        {
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': settings.apiKey,
                            },
                        },
                    )
                    .then((response: any) => {
                        if (response.data.token) {
                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isSigningUp: false,
                                    signUpError: null,
                                    token: response.data.token,
                                };
                            });

                            if (response.data.token) {
                                // Fetch user data
                                fetchMe();
                            }

                            // Refresh token every 5 minutes
                            setTimeout(verifyUser, 5 * 60 * 1000);

                            resolve(response.data.token);
                        } else {
                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isSigningUp: false,
                                    signUpError: 'Could not signup. Unknown error occured.',
                                    token: null,
                                    me: null,
                                };
                            });

                            reject('Could not sign up. Unknown error occured.');
                        }
                    })
                    .catch((error) => {
                        if (error && error.response) {
                            if (error.response.status === 400) {
                                setState((oldValues) => {
                                    return {
                                        ...oldValues,
                                        isSigningUp: false,
                                        signUpError: error.response.data.message,
                                        token: null,
                                        me: null,
                                    };
                                });

                                reject('Please fill all the fields correctly!');
                            } else {
                                console.log(error.response);

                                setState((oldValues) => {
                                    return {
                                        ...oldValues,
                                        isSigningUp: false,
                                        signUpError: 'Unknown error occurred.',
                                        token: null,
                                        me: null,
                                    };
                                });

                                reject('Unknown error occurred.');
                            }
                        } else {
                            console.error(error);

                            console.log(error.response);

                            setState((oldValues) => {
                                return {
                                    ...oldValues,
                                    isSigningUp: false,
                                    signUpError: error.message || 'Unknown error occurred.',
                                    token: null,
                                    me: null,
                                };
                            });

                            reject(error.message || 'Unknown error occurred.');
                        }
                    });
            });
        },
        [fetchMe, settings.apiBaseUrl, settings.apiKey, verifyUser],
    );

    /**
     * Update available credit in the Me details in state
     * @param availableCredit available credit
     */
    const updateAvailableCredit = useCallback(
        (availableCredit: number) => {
            if (!state.me) {
                return;
            }

            setState((oldValues) => {
                return {
                    ...oldValues,
                    me: { ...oldValues.me, availableCredit },
                };
            });
        },
        [state.me],
    );

    /**
     * Set functions to state
     */
    useEffect(() => {
        setState((oldValues) => {
            return { ...oldValues, logout, login, signup, updateAvailableCredit };
        });
    }, [login, logout, signup, updateAvailableCredit]);

    return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

const useUser = () => useContext(UserContext);

export { UserContext, UserProvider, useUser };
