import { Alert, Grid, Typography } from '@mui/material';

const CsvDuplicateCount = ({ duplicateCount }: { duplicateCount: number }) => {
    return (
        <>
            {duplicateCount > 0 && (
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <Alert severity="warning">
                            <Typography variant="body1">
                                {duplicateCount.toLocaleString()} duplicates found and removed
                            </Typography>
                        </Alert>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CsvDuplicateCount;
