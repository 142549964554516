import { Grid, Typography } from '@mui/material';
import { ParseError } from 'papaparse';

interface Props {
    csvParseErrors: ParseError[];
}

const CsvParseErrors = (props: Props) => {
    const { csvParseErrors } = props;

    return (
        <>
            {csvParseErrors.length > 0 && (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h4">CSV Parse Errors</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ul>
                            {csvParseErrors.map((error, index) => (
                                <li key={index}>{error.message}</li>
                            ))}
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        Please fix the errors in your CSV file and try again. If you need help,
                        please contact support.
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CsvParseErrors;
