import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
    Avatar,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import useUsers, { IUser } from '../../hooks/useUsers';
import UserDetails from './UserDetails';

const ScrollableUsersContainer = styled('div')(
    ({ theme }) =>
        ({
            height: 'calc(100vh - 64px)', // 64px is the height of the AppBar
            width: '340px',
            borderRight: `1px solid ${theme.palette.divider}`,
        } as const),
);

// Scrollable full-height div for users list
const ScrollableUsers = styled('div')(
    ({ theme }) =>
        ({
            // height: '100',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                outline: '1px solid slategrey',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&::-webkit-scrollbar-thumb:active': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&::-webkit-scrollbar-corner': {
                backgroundColor: 'transparent',
                outline: '1px solid slategrey',
            },
            '&::-webkit-resizer': {
                backgroundColor: 'transparent',
                outline: '1px solid slategrey',
            },
        } as const),
);

const ScrollableUserDetailsContainer = styled('div')(
    ({ theme }) =>
        ({
            height: 'calc(100vh - 64px)', // 64px is the height of the AppBar
            // width: '100%',
            backgroundColor: theme.palette.grey[300],
            // padding: theme.spacing(2),
            overflowY: 'auto',
        } as const),
);

const UserMenuItem = (props: { user: IUser }) => {
    const { user } = props;
    const theme = useTheme();
    const location = useLocation();

    // Append the user ID to the URL path
    const path = `/users/${user._id}`;

    return (
        <ListItemButton component={Link} to={path} selected={location.pathname === path}>
            <ListItemIcon>
                <Avatar
                    alt={user.firstName}
                    src={user.avatar}
                    sx={{ color: theme.palette.common.white }}
                >
                    {user.firstName && user.lastName
                        ? `${user.firstName.charAt(0).toUpperCase()}${user.lastName
                              .charAt(0)
                              .toUpperCase()}`
                        : 'U'}
                </Avatar>
            </ListItemIcon>
            <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                secondary={`${user.email} ${user.role} ${user.availableCredit.toLocaleString()}`}
            />
        </ListItemButton>
    );
};

const Users = () => {
    const theme = useTheme();
    const { data, isFetching, refetch } = useUsers();
    const params = useParams();

    return (
        <Grid container spacing={0}>
            <Grid item xs="auto">
                <ScrollableUsersContainer>
                    <div style={{ height: '64px', padding: theme.spacing(1) }}>
                        <Grid container spacing={0} alignItems={'center'} sx={{ height: '100%' }}>
                            <Grid item xs>
                                <Typography variant="h5" display={'inline'}>
                                    Users
                                </Typography>{' '}
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontFamily={'monospace'}
                                    display={'inline'}
                                >
                                    ({data && data.users.length})
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton aria-label="search" size="small">
                                    <SearchIcon color="primary" fontSize="medium" />
                                </IconButton>
                            </Grid>
                            <Grid item xs="auto">
                                <IconButton aria-label="add" size="small">
                                    <AddIcon color="primary" fontSize="medium" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider />
                    <ScrollableUsers>
                        <List sx={{ pt: 0 }}>
                            {data &&
                                !isFetching &&
                                data.users.map((user) => (
                                    <UserMenuItem key={user._id} user={user} />
                                ))}
                        </List>
                    </ScrollableUsers>
                </ScrollableUsersContainer>
            </Grid>
            <Grid item xs>
                <ScrollableUserDetailsContainer>
                    {params.userId && (
                        <UserDetails userId={params.userId} refetchUsersList={refetch} />
                    )}
                </ScrollableUserDetailsContainer>
            </Grid>
        </Grid>
    );
};

export default Users;
