import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

/**
 * Fetches all of the user's email lookup batches from the API
 * @returns List of batch IDs
 */
const useValid8HistoryBatches = () => {
    const settings = useSettings();
    const user = useUser();

    const fetchData = async (): Promise<{ batchId: string; count: number }[]> => {
        return axios
            .get(`${settings.apiBaseUrl}users/${user.me.email}/batches`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                return response.data as { batchId: string; count: number }[];
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    return useQuery(['emailLookupHistoryBatches', user.me], fetchData, {
        enabled: !user.isLoggingIn && user.token && user.me && user.me.email ? true : false,
        refetchOnWindowFocus: false,
    });
};

export default useValid8HistoryBatches;
