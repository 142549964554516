import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import {
    EmailLookupResultToMaterialColor,
    EmailTestResult,
    emailLookupResultToHumanReadable,
} from '../../types/testEmail';

interface TestEmailResultTableProps {
    testEmailResults: EmailTestResult;
}

const TestEmailResultTable = (props: TestEmailResultTableProps) => {
    const { testEmailResults } = props;

    return (
        <Paper variant="outlined">
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Job ID</TableCell>
                        <TableCell>
                            {testEmailResults.jobId ? testEmailResults.jobId : 'N/A'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>{testEmailResults.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Message</TableCell>
                        <TableCell>{testEmailResults.message}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Result</TableCell>
                        <TableCell>{testEmailResults.result}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Available Credit</TableCell>
                        <TableCell>{testEmailResults.availableCredit}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
};

interface TestEmailResultAlertProps {
    testEmailResult: EmailTestResult;
    isAdmin?: boolean;
}

const TestEmailResultAlert = (props: TestEmailResultAlertProps) => {
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item>
                    <Box
                        sx={{
                            borderRadius: '5px',
                            backgroundColor: EmailLookupResultToMaterialColor(
                                props.testEmailResult.result,
                            ),
                            padding: theme.spacing(2),
                            width: 'fit-content',
                            minWidth: theme.breakpoints.values.sm / 4,
                        }}
                    >
                        <Typography variant="h5" align="center">
                            {emailLookupResultToHumanReadable(props.testEmailResult.result)}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {props.isAdmin && (
                <Grid
                    container
                    spacing={2}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{ mt: 2 }}
                >
                    <Grid item>
                        <TestEmailResultTable testEmailResults={props.testEmailResult} />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default TestEmailResultAlert;
