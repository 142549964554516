import { Grid, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import useValid8EmailLookupStats from '../../hooks/useValid8EmailLookupStats';

type Props = {
    batchId?: string;
};

const EmailStatsCounter = (props: Props) => {
    const theme = useTheme();
    const { data, isFetching } = useValid8EmailLookupStats(props.batchId);

    return (
        <Paper elevation={3} style={{ height: '100%', padding: '16px' }}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography variant="h6">Processed</Typography>
                    <Typography variant="h4" align="right" fontFamily={'monospace'}>
                        {isFetching ? <Skeleton /> : data ? data.total.toLocaleString() : 0}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h6">Valid</Typography>
                    <Typography
                        variant="h4"
                        align="right"
                        color={theme.palette.success.dark}
                        fontFamily={'monospace'}
                    >
                        {isFetching ? <Skeleton /> : data ? data.valid.toLocaleString() : 0}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h6">Invalid</Typography>
                    <Typography variant="h4" align="right" color="error" fontFamily={'monospace'}>
                        {isFetching ? <Skeleton /> : data ? data.invalid.toLocaleString() : 0}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="h6">Catch-All</Typography>
                    <Typography
                        variant="h4"
                        align="right"
                        color={theme.palette.warning.main}
                        fontFamily={'monospace'}
                    >
                        {isFetching ? <Skeleton /> : data ? data.catchAll.toLocaleString() : 0}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EmailStatsCounter;
