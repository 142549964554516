import { tlds } from '@hapi/tlds';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Joi from 'joi';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

// Define user roles array
export const arrayUserRoles = ['user', 'admin', 'moderator'];

// Create roles type from user roles array
export type UserRoles = (typeof arrayUserRoles)[number];

export interface IAddress {
    _id?: string;
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    country: string;
    zipCode: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export const IAddressSchema = Joi.object<IAddress>({
    _id: Joi.string().trim(),
    street1: Joi.string().trim(),
    street2: Joi.string().trim(),
    city: Joi.string().trim(),
    state: Joi.string().trim(),
    country: Joi.string().trim().required(),
    zipCode: Joi.string().trim().required(),
    createdAt: Joi.date(),
    updatedAt: Joi.date(),
});

export interface IUserSession {
    _id?: string;
    refreshToken: string;
}

export interface IUserNewRequest {
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
    addresses?: IAddress[];
}

export const IUserNewRequestSchema = Joi.object<IUserNewRequest>({
    email: Joi.string()
        .trim()
        .lowercase()
        .email({ tlds: { allow: tlds } })
        .required(),
    firstName: Joi.string().trim(),
    lastName: Joi.string().trim(),
    phone: Joi.string().trim(),
    addresses: Joi.array().items(
        Joi.object({
            street1: Joi.string().trim(),
            street2: Joi.string().trim(),
            city: Joi.string().trim(),
            state: Joi.string().trim(),
            country: Joi.string().trim().required(),
            zipCode: Joi.string().trim().required(),
        }),
    ),
});

export const IUserSchema = Joi.object<IUser>({
    _id: Joi.string().trim().required(),
    email: Joi.string()
        .trim()
        .lowercase()
        .email({ tlds: { allow: tlds } })
        .required(),
    firstName: Joi.string().trim(),
    lastName: Joi.string().trim(),
    phone: Joi.string().trim(),
    addresses: Joi.array().items(
        Joi.object({
            street1: Joi.string().trim(),
            street2: Joi.string().trim(),
            city: Joi.string().trim(),
            state: Joi.string().trim(),
            country: Joi.string().trim().required(),
            zipCode: Joi.string().trim().required(),
        }),
    ),
    role: Joi.string()
        .trim()
        .valid(...arrayUserRoles)
        .required(),
    avatar: Joi.string().trim(),
    apiKey: Joi.string().trim(),
    availableCredit: Joi.number().required(),
    createdAt: Joi.date(),
    updatedAt: Joi.date(),
    deletedAt: Joi.date(),
    refreshToken: Joi.array().items(
        Joi.object({
            refreshToken: Joi.string().trim().required(),
        }),
    ),
});

export interface IUser extends IUserNewRequest {
    _id?: string;
    role: UserRoles;
    avatar?: string;
    apiKey?: string;
    availableCredit: number;
    createdAt?: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    refreshToken?: IUserSession[];
}

/**
 * Fetches users from the API
 * @param batchId Batch ID
 * @returns Normalized email lookup stats
 */
const useUsers = (userId?: string) => {
    const settings = useSettings();
    const user = useUser();

    const fetchEmailLookupHistory = async (): Promise<{ success: boolean; users: IUser[] }> => {
        return axios
            .get(`${settings.apiBaseUrl}auth/users${userId ? `/${userId}` : ''}`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                return response.data as { success: boolean; users: IUser[] };
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    const userQuery = useQuery(['adminUsers', user.me, userId], fetchEmailLookupHistory, {
        enabled:
            !user.isLoggingIn && user.token && user.me && user.me.email
                ? true
                : false && (user.me.role === 'admin' || user.me.role === 'moderator'),
        refetchOnWindowFocus: false,
    });

    // Update user via mutate hook
    const updateUser = async (userId: string, newUserData: IUser) => {
        // Clone the user object
        const clonedUser = { ...newUserData };
        // Remove _id from the newUserData object
        delete clonedUser._id;

        return axios
            .put(`${settings.apiBaseUrl}auth/users/${userId}`, clonedUser, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                const updatedUser = response.data as {
                    success: boolean;
                    user: IUser;
                    error?: string;
                };

                if (updatedUser.success) {
                    userQuery.refetch();
                }

                return updatedUser;
            })
            .catch((error) => {
                throw new Error(
                    error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : error.message,
                );
            });
    };

    // Add an address to the user via mutate hook
    const addAddress = async (userId: string, newAddress: IAddress) => {
        return axios
            .post(`${settings.apiBaseUrl}auth/users/${userId}/addresses`, newAddress, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                const updatedUser = response.data as {
                    success: boolean;
                    user: IUser;
                    error?: string;
                };

                if (updatedUser.success) {
                    userQuery.refetch();
                }

                return updatedUser;
            })
            .catch((error) => {
                throw new Error(
                    error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : error.message,
                );
            });
    };

    // Delete an address from the user via mutate hook
    const deleteAddress = async (userId: string, addressId: string) => {
        return axios
            .delete(`${settings.apiBaseUrl}auth/users/${userId}/addresses/${addressId}`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                const updatedUser = response.data as {
                    success: boolean;
                    user: IUser;
                    error?: string;
                };

                if (updatedUser.success) {
                    userQuery.refetch();
                }

                return updatedUser;
            })
            .catch((error) => {
                throw new Error(
                    error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : error.message,
                );
            });
    };

    // Add available credit to a user via mutate hook
    const addCredit = async (userId: string, amount: number) => {
        return axios
            .post(
                `${settings.apiBaseUrl}auth/users/${userId}/credit`,
                { amount },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': settings.apiKey,
                        Authorization: `Bearer ${user.token}`,
                    },
                },
            )
            .then((response) => {
                const updatedUser = response.data as {
                    success: boolean;
                    user: IUser;
                    error?: string;
                };

                if (updatedUser.success) {
                    userQuery.refetch();
                }

                return updatedUser;
            })
            .catch((error) => {
                throw new Error(
                    error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : error.message,
                );
            });
    };

    return { ...userQuery, updateUser, addAddress, deleteAddress, addCredit };
};

export default useUsers;
