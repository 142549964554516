/**
 * Checks if a string is a valid email
 * @param email Email to validate
 * @returns True if valid email, false otherwise
 */
export const isValidEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email.toLowerCase());
};

/**
 * Sorts an array of strings
 * @param array Array to sort
 * @returns Sorted array
 */
export const sortStringArray = (array: string[]) => {
    return array.sort((a, b) => {
        if (a < b) {
            return -1;
        }

        if (a > b) {
            return 1;
        }

        return 0;
    });
};

/**
 * Converts an ObjectID string to a date
 * @param objectId ObjectID string
 * @returns Date
 */
export const objectIdToDate = (objectId: string): Date => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};
