import { LoadingButton } from '@mui/lab';
import { Alert, Box, Link, TextField } from '@mui/material';
import { useState } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { useUser } from '../../hooks/useUser';

interface FormDataLogin {
    email?: string;
    password?: string;
}

const Login = () => {
    const dialog = useDialog();
    const user = useUser();
    const [formData, setFormData] = useState<FormDataLogin>({});

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const isValidEmail = (email: string): boolean => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(email.toLowerCase());
    };

    const handleFormSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        if (!isValidEmail(formData.email || '') || !formData.password) {
            dialog
                .alert({ title: 'Error', message: 'Please provide valid email and password.' })
                .then(() => {});
        } else {
            if (formData.email && formData.password) {
                user.login(formData.email, formData.password);
            }
        }
    };

    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleInputChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleInputChange}
            />
            {user.maintenanceMode ? (
                <Alert severity="warning" sx={{ mt: 2 }}>
                    The system is currently in maintenance mode.
                    <br />
                    We will be back shortly.
                </Alert>
            ) : (
                <Alert severity="error" sx={{ mt: 2, display: user.loginError ? 'block' : 'none' }}>
                    {user.loginError}
                </Alert>
            )}
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={user.isLoggingIn}
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => {
                    handleFormSubmit(e);
                }}
            >
                Login
            </LoadingButton>
            <Link
                component="button"
                variant="body2"
                onClick={() => {
                    // Implement the logic here when the reset password link is clicked.
                    console.log('Reset password link clicked');
                }}
                sx={{ mt: 2, cursor: 'pointer', textDecoration: 'underline' }}
            >
                Forgot password?
            </Link>
        </Box>
    );
};

export default Login;
