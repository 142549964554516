import { Box, Skeleton, Typography } from '@mui/material';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDialog } from '../../hooks/useDialog';
import useUsers, { IAddress, IUser, IUserSchema } from '../../hooks/useUsers';
import UserDetailsAddCredit from './UserDetailsAddCredit';
import UserDetailsAddresses from './UserDetailsAddresses';
import UserDetailsAddressesAddForm from './UserDetailsAddressesAddForm';
import UserDetailsBasicForm from './UserDetailsBasicForm';

const UserDetails = (props: {
    userId: string;
    refetchUsersList?: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<
        QueryObserverResult<
            {
                success: boolean;
                users: IUser[];
            },
            unknown
        >
    >;
}) => {
    const { userId, refetchUsersList } = props;
    const { data, isFetching, updateUser, addAddress, deleteAddress, addCredit } = useUsers(userId);
    const dialog = useDialog();
    const user: IUser | undefined = data?.users[0];
    const [formData, setFormData] = useState<IUser>({} as IUser);
    const [showAddAddressForm, setShowAddAddressForm] = useState(false);

    useEffect(() => {
        setFormData(user || ({} as IUser));
    }, [user]);

    // Validate form using Joi and IUserNewRequestSchema
    const { error } = IUserSchema.validate(formData, {
        allowUnknown: true,
    });

    const handleSave = async () => {
        if (!formData || !user || error || !user._id) {
            await dialog
                .alert({
                    title: 'Error',
                    message: error?.details[0].message || 'Unknown error',
                })
                .then(() => {});

            return;
        }

        const result = await updateUser(user._id, formData).catch(async (error) => {
            console.error(error);

            await dialog
                .alert({
                    title: 'Error',
                    message: error.message,
                })
                .then(() => {});
        });

        if (result && !result.success) {
            await dialog
                .alert({
                    title: 'Error',
                    message: result.error ? result.error : 'Unknown error',
                })
                .then(() => {});
        }

        if (result && result.success) {
            setFormData(result.user);

            if (refetchUsersList) {
                refetchUsersList();
            }

            await dialog
                .alert({
                    title: 'Success',
                    message: 'User updated successfully',
                })
                .then(() => {});
        }
    };

    const handleShowAddAddressForm = () => {
        setShowAddAddressForm(true);
    };

    const handleCancelAddAddressForm = () => {
        setShowAddAddressForm(false);
    };

    const handleAddAddress = async (formDataAddress: IAddress) => {
        if (!formData || !user || error || !user._id) {
            await dialog
                .alert({
                    title: 'Error',
                    message: error?.details[0].message || 'Unknown error',
                })
                .then(() => {});

            return;
        }

        const result = await addAddress(user._id, formDataAddress).catch(async (error) => {
            console.error(error);

            await dialog
                .alert({
                    title: 'Error',
                    message: error.message,
                })
                .then(() => {});
        });

        if (result && !result.success) {
            await dialog
                .alert({
                    title: 'Error',
                    message: result.error ? result.error : 'Unknown error',
                })
                .then(() => {});
        }

        if (result && result.success) {
            setFormData(result.user);
            setShowAddAddressForm(false);

            await dialog
                .alert({
                    title: 'Success',
                    message: 'Address added successfully',
                })
                .then(() => {});
        }
    };

    const handleDeleteAddress = async (addressId: string) => {
        dialog
            .confirm({
                title: 'Delete Address',
                message: 'Are you sure you want to delete this address?',
            })
            .then(async () => {
                if (!formData || !user || error || !user._id) {
                    await dialog
                        .alert({
                            title: 'Error',
                            message: error?.details[0].message || 'Unknown error',
                        })
                        .then(() => {});

                    return;
                }

                const result = await deleteAddress(user._id, addressId).catch(async (error) => {
                    console.error(error);

                    await dialog
                        .alert({
                            title: 'Error',
                            message: error.message,
                        })
                        .then(() => {});
                });

                if (result && !result.success) {
                    await dialog
                        .alert({
                            title: 'Error',
                            message: result.error ? result.error : 'Unknown error',
                        })
                        .then(() => {});
                }

                if (result && result.success) {
                    setFormData(result.user);
                    setShowAddAddressForm(false);

                    await dialog
                        .alert({
                            title: 'Success',
                            message: 'Addresss deleted successfully',
                        })
                        .then(() => {});
                }
            })
            .catch(() => {});
    };

    const handleAddCredit = async (credit: number) => {
        if (!formData || !user || error || !user._id) {
            await dialog
                .alert({
                    title: 'Error',
                    message: error?.details[0].message || 'Unknown error',
                })
                .then(() => {});

            return;
        }

        const result = await addCredit(user._id, credit).catch(async (error) => {
            console.error(error);

            await dialog
                .alert({
                    title: 'Error',
                    message: error.message,
                })
                .then(() => {});
        });

        if (result && !result.success) {
            await dialog
                .alert({
                    title: 'Error',
                    message: result.error ? result.error : 'Unknown error',
                })
                .then(() => {});
        }

        if (result && result.success) {
            setFormData(result.user);

            if (refetchUsersList) {
                refetchUsersList();
            }

            await dialog
                .alert({
                    title: 'Success',
                    message: 'Credit added successfully',
                })
                .then(() => {});
        }
    };

    return (
        <Box p={2}>
            {isFetching ? (
                <Skeleton />
            ) : (
                <>
                    {!user ? (
                        <Typography variant="h6" color="error">
                            User not found
                        </Typography>
                    ) : (
                        <>
                            <Typography variant="h5" sx={{ mt: 2, mb: 4 }}>
                                {user.firstName} {user.lastName}
                            </Typography>

                            <UserDetailsBasicForm
                                user={user}
                                formData={formData}
                                setFormData={setFormData}
                                error={error}
                                handleSave={handleSave}
                            />

                            <UserDetailsAddCredit
                                availbleCredit={user.availableCredit}
                                handleAddCredit={handleAddCredit}
                            />

                            <UserDetailsAddresses
                                addresses={user.addresses}
                                handleShowAddAddressForm={handleShowAddAddressForm}
                                handleDeleteAddress={handleDeleteAddress}
                            />

                            <UserDetailsAddressesAddForm
                                handleAddAddress={handleAddAddress}
                                handleCancelAddAddressForm={handleCancelAddAddressForm}
                                visible={showAddAddressForm}
                            />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default UserDetails;
