import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EmailTestResult } from '../types/testEmail';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

interface Valid8ContextType {
    testEmail: (
        email: string,
        batchId?: string | null,
        workerHostname?: string,
    ) => Promise<EmailTestResult>;
    isFetchingTestEmail: boolean;
}

let initialState: Valid8ContextType = {
    testEmail: (email: string, batchId?: string | null, workerHostname?: string) =>
        new Promise(() => {}),
    isFetchingTestEmail: false,
};

const Valid8Context = React.createContext<Valid8ContextType>(initialState);

const Valid8Provider = ({ children }: any) => {
    const settings = useSettings();
    const user = useUser();
    const [state, setState] = useState(initialState);

    const testEmail = useCallback(
        async (
            email: string,
            batchId?: string | null,
            workerHostname?: string,
        ): Promise<EmailTestResult> => {
            if (!user.me) {
                throw new Error('User not logged in');
            }

            if (!email) {
                throw new Error('Email is required');
            }

            setState((oldValues) => {
                return { ...oldValues, isFetchingTestEmail: true };
            });

            const result = await axios
                .get(`${settings.apiBaseUrl}email/check`, {
                    params: {
                        userEmail: user.me.email,
                        userApiKey: user.me.apiKey,
                        email: email,
                        batchId,
                        workerHostname,
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': settings.apiKey,
                    },
                })
                .then((response) => {
                    if (response.data.availableCredit) {
                        user.updateAvailableCredit(response.data.availableCredit);
                    }

                    setState((oldValues) => {
                        return { ...oldValues, isFetchingTestEmail: false };
                    });

                    return response.data as EmailTestResult;
                })
                .catch((error) => {
                    setState((oldValues) => {
                        return { ...oldValues, isFetchingTestEmail: false };
                    });

                    throw new Error(error.response.data.error || error);
                });

            return result;
        },
        [settings.apiBaseUrl, settings.apiKey, user],
    );

    /**
     * Set functions to state
     */
    useEffect(() => {
        setState((oldValues) => {
            return { ...oldValues, testEmail };
        });
    }, [testEmail]);

    return <Valid8Context.Provider value={state}>{children}</Valid8Context.Provider>;
};

const useValid8 = () => useContext(Valid8Context);

export { Valid8Context, Valid8Provider, useValid8 };
