import MenuIcon from '@mui/icons-material/Menu';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useUser } from '../../../hooks/useUser';

type Props = {
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderToolbar = (props: Props) => {
    const { setDrawerOpen } = props;
    const theme = useTheme();
    const user = useUser();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Toolbar>
            <IconButton
                color="inherit"
                onClick={() => setDrawerOpen((open: boolean) => !open)}
                edge="start"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>

            <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                <span style={{ fontWeight: 'bold' }}>Valid8</span>
            </Typography>

            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={'Sexter 1'}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                            src={!user.isLoggingIn && user.me && user.me.avatar}
                            sx={{ color: theme.palette.common.white }}
                        >
                            {!user.isLoggingIn &&
                                user.me &&
                                user.me.email &&
                                (user.me.email as string).charAt(0).toUpperCase()}
                        </Avatar>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuItem
                        onClick={() => {
                            // handleProfileDialogToggle();
                            // handleCloseUserMenu();
                        }}
                    >
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={async () => {
                            user.logout();
                        }}
                    >
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Toolbar>
    );
};

export default HeaderToolbar;
