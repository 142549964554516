import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import Joi from 'joi';
import { useState } from 'react';

const UserDetailsAddCredit = (props: {
    availbleCredit: number;
    handleAddCredit: (amount: number) => void;
}) => {
    const { availbleCredit, handleAddCredit } = props;
    const [amount, setAmount] = useState(0);

    const { error } = Joi.object({
        amount: Joi.number().min(0).required(),
    }).validate({ amount });

    return (
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4} alignItems={'center'}>
                <Grid item xs={12}>
                    <Typography variant="h5">Add Credit</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Current Credit: {availbleCredit.toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <TextField
                        label="Amount"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(parseInt(e.target.value, 10))}
                        error={!!error}
                        helperText={error?.message}
                    />
                </Grid>
                <Grid item xs>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleAddCredit(amount);
                        }}
                    >
                        Add Credit
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserDetailsAddCredit;
