import { styled } from '@mui/material';
import { useUser } from './hooks/useUser';
import Dashboard from './pages/dashboard/Dashboard';
import Welcome from './pages/login/Welcome';

const ContainerFullWidth = styled('div')({
    width: '100%',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
});

const App: React.FC = () => {
    const user = useUser();

    return <ContainerFullWidth>{user.token ? <Dashboard /> : <Welcome />}</ContainerFullWidth>;
};

export default App;
