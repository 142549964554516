import { Box, Grid, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import QuickTestEmailCard from '../../components/testEmail/QuickTestEmailCard';
import TestEmailResultsReport from '../../components/testEmail/TestEmailResultsReport';
import UserProfileCard from '../../components/user/UserProfileCard';
import { useUser } from '../../hooks/useUser';
import { EmailTestResult } from '../../types/testEmail';

const DashboardHome = () => {
    const user = useUser();
    const theme = useTheme();
    const [lastTestResult, setLastTestResult] = useState<EmailTestResult | null>(null);

    return (
        <Box p={2}>
            <Box mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <UserProfileCard />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
                            <Typography variant="h5">Some Number</Typography>
                            <Typography variant="h3" align="right" fontFamily={'monospace'}>
                                42
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} style={{ padding: theme.spacing(2) }}>
                            <Typography variant="h5">Available Credit</Typography>
                            <Typography variant="h3" align="right" fontFamily={'monospace'}>
                                {user.me ? (
                                    <span
                                        style={{
                                            color:
                                                user.me.availableCredit > 0
                                                    ? theme.palette.success.dark
                                                    : theme.palette.error.main,
                                        }}
                                    >
                                        {user.me.availableCredit.toLocaleString()}
                                    </span>
                                ) : (
                                    <Skeleton variant="text" />
                                )}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            <QuickTestEmailCard setLastTestResult={setLastTestResult} />

            <Box mt={2}>
                <TestEmailResultsReport
                    showStats={true}
                    maximumRows={10}
                    lastJobId={lastTestResult?.jobId}
                />
            </Box>
        </Box>
    );
};

export default DashboardHome;
