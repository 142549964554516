import { Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import Dropzone, { FileError } from 'react-dropzone';

interface CsvFileInputProps {
    setFile: (file: File) => void;
    apiKeyValid: boolean;
}

//  create a Material UI csv file input from component
const CsvFileInput = (props: CsvFileInputProps) => {
    const { setFile, apiKeyValid } = props;
    const theme: Theme = useTheme();
    const [error, setError] = useState<string | undefined>();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]);
        }
    }, []);

    // Make sure the file is a csv file
    const validateFile = (file: File): FileError | null => {
        if (file.type !== 'text/csv') {
            setError('Selected file is not a CSV file.');

            return {
                code: 'file-invalid-type',
                message: 'Selected file is not a CSV file.',
            };
        }

        setError(undefined);

        return null;
    };

    return (
        <Paper
            variant="elevation"
            sx={{ marginBottom: theme.spacing(2), padding: theme.spacing(2) }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4">1. CSV File Input</Typography>
                </Grid>
                <Grid item xs={12}>
                    Select a CSV file. The system will automatically pick up the column with
                    &quot;email&quot; in the title and validate every row.
                </Grid>
                <Grid item xs={12}>
                    <Dropzone
                        maxFiles={1}
                        multiple={false}
                        accept={{
                            'text/csv': ['.csv'],
                        }}
                        validator={validateFile}
                        disabled={!apiKeyValid}
                        onDrop={onDrop}
                        onDragLeave={() => setError(undefined)}
                    >
                        {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                            <div
                                {...getRootProps()}
                                style={{
                                    border: '1px dashed grey',
                                    padding: theme.spacing(2),
                                    cursor: apiKeyValid ? 'pointer' : 'not-allowed',
                                    backgroundColor: isDragActive ? '#eee' : 'white',
                                }}
                            >
                                <input {...getInputProps()} />
                                <Typography variant="body1" color={theme.palette.grey[600]}>
                                    Drag and drop a CSV here, or click to select file.
                                </Typography>
                                {acceptedFiles && acceptedFiles.length > 0 && (
                                    <Typography variant="body1" color={theme.palette.grey[600]}>
                                        <strong>{acceptedFiles[0].name}</strong>
                                    </Typography>
                                )}
                                {error && (
                                    <Typography variant="body1" color={theme.palette.error.main}>
                                        {error}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </Dropzone>
                </Grid>
                {/* <Grid item xs={12}>
                    <Button variant="contained" component="label" disabled={!apiKeyValid}>
                        Upload CSV File
                        <input
                            type="file"
                            hidden
                            accept=".csv"
                            value={''}
                            onChange={(e) => {
                                const target: HTMLInputElement = e.target as HTMLInputElement;

                                if (target.files) {
                                    const file = target.files.item(0);

                                    if (file && validateFile(file)) {
                                        setFile(file);
                                    }
                                }
                            }}
                        />
                    </Button>
                </Grid> */}
                {/* {error && (
                    <Grid item xs={12}>
                        <Typography variant="body1" color={theme.palette.error.main}>
                            {error}
                        </Typography>
                    </Grid>
                )} */}
            </Grid>
        </Paper>
    );
};

export default CsvFileInput;
