import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

export interface IWorkerDetails {
    ip: string;
    hostname: string;
    port: number;
}

export const arrayEmailLookupResult = [
    'valid',
    'invalid',
    'catch-all',
    'mx_not_found',
    'telnet_error',
    'blocked',
    'unknown',
    'no-workers',
    'invalid-user',
    'no-credit',
] as const;
export type WorkerEmailLookupResult = (typeof arrayEmailLookupResult)[number];

export interface IWorkerTestEmailResponse {
    email: string;
    userId: string | undefined;
    domain: string;
    mxExchange: string | null;
    transcript: string[];
    urls: string[];
    result: WorkerEmailLookupResult;
    timeTakenMS: number;
    worker?: IWorkerDetails;
}

export interface IMainTestEmailResponse extends IWorkerTestEmailResponse {
    jobId: string;
    batchId?: string;
    success: boolean;
    message: string;
    requestError: boolean;
    cachedResult: boolean;
    timeTakenFullMS: number;
}

/**
 * Describes a server machine
 */
export interface IMachine {
    ip: string;
    hostname: string;
    port: number;
}

/**
 * Array of valid log types
 */
export const arrayLogType = ['info', 'error', 'warning', 'result', 'blocked'] as const;

export type LogType = (typeof arrayLogType)[number];

/**
 * Log record base interface
 */
export interface ILog {
    type: LogType;
    machine: IMachine;
    requestIp?: string;
}

/**
 * Log record of the email test result
 */
export interface ILogResult extends ILog {
    type: 'result';
    result: IMainTestEmailResponse;
}

/**
 * Log record of a blocked server
 */
export interface ILogBlocked extends ILog {
    type: 'blocked';
    result: IMainTestEmailResponse;
}

/**
 * Log record of an info
 */
export interface ILogInfo extends ILog {
    type: 'info';
    message?: string;
    info: any;
}

/**
 * Log record of an error
 */
export interface ILogError extends ILog {
    type: 'error';
    message?: string;
    error?: any;
}

/**
 * Log record of a warning
 */
export interface ILogWarning extends ILog {
    type: 'warning';
    message?: string;
    warning?: any;
}

export type ILogRecord = ILogInfo | ILogError | ILogWarning | ILogResult | ILogBlocked;

/**
 * Fetches the raw log of an email lookup from the API
 * @param userId User ID
 * @param jobId Job ID
 * @returns Normalized email lookup stats
 */
const useValid8Log = (userId: string, jobId: string) => {
    const settings = useSettings();
    const user = useUser();

    const fetchData = async (): Promise<ILogRecord> => {
        return axios
            .get(`${settings.apiBaseUrl}users/${userId}/logs/${jobId}`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                return response.data as ILogRecord;
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    return useQuery(['jobLog', user.me, userId, jobId], fetchData, {
        enabled: !user.isLoggingIn && user.token && user.me && user.me.email ? true : false,
        refetchOnWindowFocus: false,
    });
};

export default useValid8Log;
