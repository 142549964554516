import StarIcon from '@mui/icons-material/Star';
import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { useUser } from '../../hooks/useUser';
import BadgeAvatars from '../common/BadgedAvatar';

const UserProfileCard = () => {
    const user = useUser();

    return (
        <Card variant="elevation" elevation={3}>
            <CardContent>
                {user.me ? (
                    <Grid container spacing={2}>
                        <Grid item>
                            <BadgeAvatars
                                avatar={user.me.avatar}
                                avatarAlt={`${user.me.firstName} ${user.me.lastName}`}
                                badge={
                                    user.me.role === 'admin' ? (
                                        <StarIcon
                                            color="success"
                                            sx={{
                                                width: 22,
                                                height: 22,
                                                paddingRight: 2.5,
                                                paddingBottom: 2.5,
                                            }}
                                        />
                                    ) : null
                                }
                                sx={{ width: 64, height: 64 }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {`${user.me.firstName} ${user.me.lastName}`}{' '}
                                {user.me.role === 'admin' ? '(Admin)' : null}
                            </Typography>
                            <Typography variant="body2">{user.me.email}</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Skeleton variant="text" />
                )}
            </CardContent>
        </Card>
    );
};

export default UserProfileCard;
