import CircleIcon from '@mui/icons-material/Circle';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Fade,
    Grid,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { useValid8 } from '../../hooks/useValid8';
import useValid8Workers from '../../hooks/useValid8Workers';
import { isValidEmail } from '../../libs/common';
import { EmailTestResult } from '../../types/testEmail';
import RawLogDialog from './RawLogDialog';
import TestEmailResultAlert from './TestEmailResultAlert';

interface Props {
    setLastTestResult: React.Dispatch<React.SetStateAction<EmailTestResult | null>>;
}

const QuickTestEmailCard = (props: Props) => {
    const { setLastTestResult } = props;
    const user = useUser();
    const valid8 = useValid8();
    const { data: valid8WorkersList, isLoading: isValid8WorkersListLoading } = useValid8Workers();
    const theme = useTheme();
    const [email, setEmail] = useState<string>('');
    const [worker, setWorker] = useState<string>('any');
    const [testResults, setTestResults] = useState<EmailTestResult | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [logDialogOpen, setLogDialogOpen] = useState<boolean>(false);

    const handleTextEmailClick = () => {
        if (isValidEmail(email)) {
            valid8
                .testEmail(email, null, worker === 'any' ? undefined : worker)
                .then((result) => {
                    setTestResults(result);
                    setLastTestResult(result);
                    setError(null);
                })
                .catch((error) => {
                    setLastTestResult(null);
                    setError(error.error || error.message || error || 'Unknown Error');
                });
        }
    };

    return (
        <Box mt={2}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs>
                    <Typography variant="h5">Check Email</Typography>
                </Grid>
                <Grid item xs="auto" textAlign={'right'}>
                    <Typography variant="body1">
                        <Button component={Link} to="/bulk">
                            Bulk Test Email
                        </Button>
                    </Typography>
                </Grid>
            </Grid>

            <form>
                <Paper
                    elevation={3}
                    style={{ padding: theme.spacing(2), marginTop: theme.spacing(2) }}
                >
                    <Grid container spacing={3} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs>
                            <TextField
                                label="Email Address"
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={email.length > 0 ? !isValidEmail(email) : false}
                            />
                        </Grid>
                        {user.me && (user.me.role === 'admin' || user.me.role === 'moderator') && (
                            <Grid item xs={'auto'}>
                                {isValid8WorkersListLoading ? (
                                    <Skeleton />
                                ) : (
                                    <Select
                                        value={worker}
                                        onChange={(e) => setWorker(e.target.value as string)}
                                        variant="outlined"
                                        size="medium"
                                        fullWidth
                                    >
                                        <MenuItem value="any">
                                            <span style={{ color: theme.palette.grey[500] }}>
                                                Any Worker
                                            </span>
                                        </MenuItem>
                                        {valid8WorkersList &&
                                            valid8WorkersList
                                                .slice()
                                                .sort((a, b) => {
                                                    return a.hostname > b.hostname ? 1 : -1;
                                                })
                                                .map((worker) => (
                                                    <MenuItem
                                                        key={worker.hostname}
                                                        value={worker.hostname}
                                                    >
                                                        <Grid
                                                            container
                                                            alignItems={'center'}
                                                            spacing={1}
                                                        >
                                                            <Grid item>
                                                                <CircleIcon
                                                                    color={
                                                                        worker.connected === 1
                                                                            ? 'success'
                                                                            : 'error'
                                                                    }
                                                                    sx={{
                                                                        fontSize: '0.7rem',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs>
                                                                {worker.hostname}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={'auto'}
                                                                textAlign={'right'}
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontFamily: 'monospace',
                                                                        color: theme.palette
                                                                            .grey[500],
                                                                    }}
                                                                >
                                                                    {worker.ip}:{worker.port}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                )}
                            </Grid>
                        )}
                        <Grid item md={'auto'}>
                            <LoadingButton
                                variant="contained"
                                type="submit"
                                fullWidth
                                endIcon={<SendIcon />}
                                size="large"
                                disabled={!isValidEmail(email)}
                                onClick={handleTextEmailClick}
                                loading={valid8.isFetchingTestEmail}
                            >
                                Check
                            </LoadingButton>
                        </Grid>
                        {error && (
                            <Fade in={true} timeout={100}>
                                <Grid item xs={12}>
                                    <Paper
                                        elevation={3}
                                        style={{
                                            padding: theme.spacing(2),
                                            backgroundColor: theme.palette.error.main,
                                        }}
                                    >
                                        <Typography variant="body1" color={'white'}>
                                            {error}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Fade>
                        )}
                        {testResults && testResults.email === email.trim().toLowerCase() && (
                            <Fade in={true} timeout={100}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems={'center'}>
                                        <Grid item xs={12}>
                                            <TestEmailResultAlert
                                                testEmailResult={testResults}
                                                isAdmin={
                                                    user.me && user.me.role === 'admin'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} textAlign={'center'}>
                                            {user.me && user.me.role === 'admin' && (
                                                <>
                                                    <RawLogDialog
                                                        userId={testResults.userId}
                                                        jobId={testResults.jobId}
                                                        open={logDialogOpen}
                                                        setOpen={setLogDialogOpen}
                                                    />
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => setLogDialogOpen(true)}
                                                    >
                                                        View Full Log
                                                    </Button>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Fade>
                        )}
                    </Grid>
                </Paper>
            </form>
        </Box>
    );
};

export default QuickTestEmailCard;
