import {
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Theme,
    styled,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSettings } from '../../hooks/useSettings';
import { useUser } from '../../hooks/useUser';
import BulkValidator from '../bulkValidator/BulkValidator';
import Report from '../report/Report';
import Users from '../users/Users';
import DashboardHome from './DashboardHome';
import DashboardDrawer from './Drawer/DashboardDrawer';
import HeaderToolbar from './Toolbar/HeaderToolbar';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerWidth: number;
    theme: Theme;
}

const AppToolbar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' && prop !== 'theme',
})<AppBarProps>(({ open, drawerWidth, theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Dashboard = () => {
    const user = useUser();
    const settings = useSettings();
    const theme = useTheme();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <BrowserRouter>
            <AppToolbar
                position="sticky"
                open={drawerOpen}
                drawerWidth={settings.ui.drawerWidth}
                theme={theme}
            >
                <HeaderToolbar setDrawerOpen={setDrawerOpen} />
            </AppToolbar>

            <DashboardDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />

            <Routes>
                <Route path="/" element={<DashboardHome />} />
                <Route path="/bulk" element={<BulkValidator />} />
                <Route path="/report" element={<Report />} />
                {user.me && user.me.role === 'admin' && (
                    <Route path="/admin" element={<div>Admin</div>} />
                )}
                {/* {user.me && (user.me.role === 'admin' || user.me.role === 'moderator') && (
                    <> */}
                <Route path="/users" element={<Users />} />
                <Route path="/users/:userId" element={<Users />} />
                <Route path="/moderator" element={<div>Moderator</div>} />
                {/* </>
                )} */}
            </Routes>
        </BrowserRouter>
    );
};

export default Dashboard;
