/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Paper, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import ObjectId from 'bson-objectid';
import Papa, { ParseError } from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { useDialog } from '../../hooks/useDialog';
import { useUser } from '../../hooks/useUser';
import { useValid8 } from '../../hooks/useValid8';
import { EmailTestResult, emailLookupResultToHumanReadable } from '../../types/testEmail';
import CsvDuplicateCount from './CsvDuplicateCount';
import CsvFileInput from './CsvFileInput';
import CsvParseErrors from './CsvParseErrors';
import CsvPreview from './CsvPreview';

// Make an array of all the emails in the CSV file that are duplicated
const removeDuplicateEmails = (
    data: any[],
    emailColumnName: string,
): { dataWithoutDuplicates: any[]; duplicateCount: number } => {
    // Create a set to keep track of seen emails and an array to store the deduplicated data
    const seenEmails = new Set<string>();
    const dataWithoutDuplicates: any[] = [];

    // Loop through the data array and add objects without duplicate emails to deduplicatedData
    let duplicateCount = 0;

    for (const row of data) {
        const email = row[emailColumnName];

        if (email) {
            if (seenEmails.has(email)) {
                duplicateCount++;
                continue; // Skip this row
            }

            seenEmails.add(email);
            dataWithoutDuplicates.push(row); // Add this row to the new data array
        }
    }

    return { dataWithoutDuplicates, duplicateCount };
};
// Set the apiUrl to the API endpoint based on the environment
const batchSize = 10;

const BulkValidator = () => {
    const theme: Theme = useTheme();
    const user = useUser();
    const valid8 = useValid8();

    const dialog = useDialog();
    const [file, setFile] = useState<File>();
    // State to store parsed data
    const [csvParsedData, setCsvParsedData] = useState<any[]>([]);
    // Store table Column names
    const [csvColumnNames, setCsvColumnNames] = useState<string[]>([]);
    // Store the name of the email column
    const [csvEmailColumnName, setCsvEmailColumnName] = useState<string>('');
    // Store CSV parse errors
    const [csvParseErrors, setCsvParseErrors] = useState<ParseError[]>([]);
    // Parsing and fetching states
    const [isParseingCsv, setIsParseingCsv] = useState<boolean>(false);
    // How many duplicate emails were removed
    const [duplicateCount, setDuplicateCount] = useState<number>(0);

    const [isFetchingResults, setIsFetchingResults] = useState<boolean>(false);
    const [loadingRows, setLoadingRows] = useState<number>(0);
    const [loadingBatch, setLoadingBatch] = useState<number>(0);
    const shouldStop = useRef(false);

    // Parse the CSV file
    useEffect(() => {
        if (!file) {
            return;
        }

        const parseFile = async () => {
            setIsParseingCsv(true);

            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    if (results.errors.length === 0 && results.meta.fields) {
                        setCsvColumnNames([...results.meta.fields, 'Result']);

                        // Find the email column
                        const emailColumn = results.meta.fields.find((field) =>
                            field.toLowerCase().includes('email'),
                        );

                        if (emailColumn) {
                            setCsvEmailColumnName(emailColumn);

                            const dataWithId: any[] = [...results.data];

                            // Add an empty string to the end of each row to hold the result
                            dataWithId.forEach((row, index: number) => {
                                row['__id'] = index;
                                row['Result'] = '';
                            });

                            // Remove duplicate emails
                            const { dataWithoutDuplicates, duplicateCount } = removeDuplicateEmails(
                                dataWithId,
                                emailColumn,
                            );

                            setDuplicateCount(duplicateCount);

                            setCsvParsedData(dataWithoutDuplicates);
                        } else {
                            setCsvParseErrors([
                                {
                                    code: 'TooFewFields',
                                    message: 'Could not file an email column in the CSV file',
                                    row: 0,
                                    type: 'FieldMismatch',
                                },
                            ]);
                            setCsvParsedData([]);
                            setCsvColumnNames([]);
                        }
                    } else {
                        setCsvParseErrors(results.errors);
                        setCsvParsedData([]);
                        setCsvColumnNames([]);
                    }
                },
            });

            setIsParseingCsv(false);
        };

        parseFile();
    }, [file]);

    // Check if the string is a valid email address
    const isValidEmail = (email: string) => {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
        return emailRegex.test(email);
    };

    // Fetch the results from the API
    const processBatch = async (batch: any[], batchId: string) => {
        return Promise.all(
            batch.map(async (record) => {
                setLoadingBatch((prevValue) => prevValue + 1);

                if (
                    record[csvEmailColumnName] &&
                    record[csvEmailColumnName].length > 0 &&
                    isValidEmail(record[csvEmailColumnName])
                ) {
                    const response = await valid8
                        .testEmail(record[csvEmailColumnName], batchId)
                        .then(async (response) => {
                            setLoadingRows((loadingRows) => loadingRows + 1);

                            return response;
                        })
                        .catch((error) => {
                            console.error(
                                `Error fetching from API for email ${record[csvEmailColumnName]}:`,
                                error,
                            );

                            return {
                                email: record[csvEmailColumnName],
                                result: 'server-error',
                                availableCredit: -1,
                                jobId: '',
                            } as EmailTestResult;
                        });

                    return response;
                } else {
                    return {
                        email: record[csvEmailColumnName],
                        result: 'invalid',
                        availableCredit: -1,
                        jobId: '',
                    } as EmailTestResult;
                }
            }),
        );
    };

    // Process the CSV file in batches
    const processInBatches = async (data: any[], batchSize: number, batchId: string) => {
        setLoadingRows(0);
        setIsFetchingResults(true);

        let stopLoop: boolean = false;
        const totalBatches = Math.ceil(data.length / batchSize);

        for (let i = 0; i < totalBatches && !stopLoop; i++) {
            if (shouldStop.current) {
                console.log('Processing stopped.');
                break;
            }

            const batchStart = i * batchSize;
            const batchEnd = batchStart + batchSize;
            const currentBatch = data.slice(batchStart, batchEnd);

            setLoadingBatch(i * batchSize);

            const results = await processBatch(currentBatch, batchId).catch((error) => {
                console.error(`Error processing batch ${i}:`, error);

                setCsvParseErrors(error.message);
            });

            if (results) {
                // Check if any of the results containts 'no-credit'
                const noCreditResults = results.find(
                    (result) => result && result.result === 'no-credit',
                );

                if (noCreditResults) {
                    // No credit left, stopping processing.
                    stopLoop = true;
                }

                // Process all results
                results.forEach(async (result) => {
                    const doUpdate = async (result: any) => {
                        if (result) {
                            const updatedData = [...csvParsedData];

                            const index = updatedData.findIndex(
                                (item) =>
                                    item[csvEmailColumnName].toLowerCase() ===
                                    result.email.toLowerCase(),
                            );

                            if (index !== -1) {
                                updatedData[index]['Result'] = result.result;

                                setCsvParsedData(updatedData);
                            } else {
                                console.error('Could not find email in CSV data:', result.email);
                            }
                        }
                    };

                    await doUpdate(result);
                });
            }
        }

        // Reset shouldStop for future processing
        shouldStop.current = stopLoop;

        setIsFetchingResults(false);
    };

    // Start processing the CSV file
    const startProcessingRecords = async () => {
        shouldStop.current = false;

        // Genearate a new Object ID batch ID
        const batchId = ObjectId().toHexString();

        await processInBatches(csvParsedData, batchSize, batchId).finally(() => {
            if (user.me && user.me.availableCredit <= 0) {
                // No credit left
                dialog
                    .alert({
                        message: 'You have no credit left. Please top up your account.',
                        title: 'Warning',
                    })
                    .then(() => {});
            }
        });
    };

    const handleStopClick = () => {
        shouldStop.current = true;
    };

    return (
        <div
            className="App"
            style={{
                padding: theme.spacing(2),
            }}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                textAlign={'left'}
                sx={{ marginBottom: theme.spacing(2) }}
            >
                <Grid item xs={12}>
                    <Typography variant="h4">Bulk Validator</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} style={{ height: '100%', padding: theme.spacing(2) }}>
                        <Typography variant="h5">Available Credit</Typography>
                        <Typography variant="h4" align="right" fontFamily={'monospace'}>
                            {user.me ? (
                                user.me.availableCredit.toLocaleString()
                            ) : (
                                <Skeleton variant="text" />
                            )}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}></Grid>
            </Grid>

            {!user.isFetchingMe && user.me && user.me.apiKey && (
                <>
                    <CsvFileInput setFile={setFile} apiKeyValid={true} />

                    <CsvParseErrors csvParseErrors={csvParseErrors} />

                    <CsvDuplicateCount duplicateCount={duplicateCount} />
                </>
            )}

            {csvParsedData.length > 0 && (
                <CsvPreview
                    isParseingCsv={isParseingCsv}
                    csvParsedData={csvParsedData}
                    csvColumnNames={csvColumnNames}
                    startProcessingRecords={startProcessingRecords}
                    isFetchingResults={isFetchingResults}
                    loadingRows={loadingRows}
                    loadingBatch={loadingBatch}
                    stoppingProcessingRecords={shouldStop.current}
                    stopProcessingRecords={handleStopClick}
                />
            )}

            {csvParsedData.length > 0 && (
                <Paper
                    sx={{
                        padding: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h4">3. Download Results</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isFetchingResults}
                                onClick={() => {
                                    // Remove the __id column from csvParsedData
                                    const csvParsedDataWithoutId = csvParsedData.map((row) => {
                                        delete row.__id;

                                        row.Result = emailLookupResultToHumanReadable(row.Result);

                                        return row;
                                    });

                                    // Download the CSV file
                                    const csv = Papa.unparse(csvParsedDataWithoutId, {
                                        header: true,
                                    });

                                    const blob = new Blob([csv], { type: 'text/csv' });
                                    const url = window.URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.href = url;
                                    a.download = 'valid-8-results.csv';
                                    document.body.appendChild(a);
                                    a.click();
                                    a.remove();
                                }}
                            >
                                {isFetchingResults ? 'Loading...' : 'Download CSV'}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </div>
    );
};

export default BulkValidator;
