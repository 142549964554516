import { Box } from '@mui/material';
import TestEmailResultsReport from '../../components/testEmail/TestEmailResultsReport';

const Report = () => {
    return (
        <Box p={2}>
            <TestEmailResultsReport showStats={true} />
        </Box>
    );
};

export default Report;
