import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    Select,
    Skeleton,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useValid8History from '../../hooks/useValid8History';
import useValid8HistoryBatches from '../../hooks/useValid8HistoryBatches';
import { objectIdToDate } from '../../libs/common';
import { NormalizedEmailLookupResult } from '../../types/testEmail';
import EmailStatsCounter from '../user/EmailStatsCounter';
import TestEmailResultsGrid from './TestEmailResultsGrid';

type Props = {
    maximumRows?: number;
    lastJobId?: string;
    showStats: boolean;
};

const TestEmailResultsReport = (props: Props) => {
    const { maximumRows, lastJobId, showStats } = props;
    const theme = useTheme();
    const [type, setType] = useState<NormalizedEmailLookupResult>('total');
    const [batchId, setBatchId] = useState<string>('all');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: maximumRows || 10,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'jobId',
            sort: 'desc',
        },
    ]);

    // Data fetching hooks
    const {
        data: batches,
        isLoading: isLoadingBatches,
        refetch: refetchBatches,
    } = useValid8HistoryBatches();

    const { data, isLoading, refetch } = useValid8History(
        type,
        paginationModel.pageSize,
        paginationModel.page * paginationModel.pageSize,
        (sortModel && sortModel.length > 0 && sortModel[0].field) || undefined,
        (sortModel && sortModel.length > 0 && sortModel[0].sort) || undefined,
        batchId === 'all' ? undefined : batchId,
    );

    useEffect(() => {
        if (lastJobId) {
            refetch();
            refetchBatches();
        }
    }, [lastJobId, refetch, refetchBatches]);

    return (
        <Box>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs>
                    <Typography variant={maximumRows ? 'h5' : 'h4'}>
                        {maximumRows ? 'Recent History' : 'Report'}
                    </Typography>
                </Grid>
                {maximumRows && (
                    <Grid item xs="auto" textAlign={'right'}>
                        <Typography variant="body1">
                            <Button component={Link} to="/report">
                                View Full Report
                            </Button>
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Paper elevation={3} style={{ padding: theme.spacing(2), marginTop: theme.spacing(2) }}>
                <Grid container spacing={2} alignItems={'center'}>
                    {!maximumRows && (
                        <Grid item xs={12} md>
                            {isLoadingBatches ? (
                                <Skeleton variant="text" />
                            ) : (
                                <Select
                                    value={batchId}
                                    onChange={(e) => setBatchId(e.target.value)}
                                    style={{ width: '100%' }}
                                >
                                    <MenuItem value={'all'}>All Batches</MenuItem>
                                    {!isLoadingBatches &&
                                        batches &&
                                        batches.map((batch) => (
                                            <MenuItem key={batch.batchId} value={batch.batchId}>
                                                <Grid container alignItems={'center'} spacing={2}>
                                                    <Grid item xs>
                                                        {moment(
                                                            objectIdToDate(batch.batchId),
                                                        ).format('L hh:mm a')}{' '}
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <span
                                                            style={
                                                                {
                                                                    // color: theme.palette.grey[500],
                                                                }
                                                            }
                                                        >
                                                            {batch.count} emails
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <span
                                                            style={{
                                                                color: theme.palette.grey[500],
                                                            }}
                                                        >
                                                            {batch.batchId}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12} md={maximumRows ? 12 : 'auto'} textAlign={'right'}>
                        <ToggleButtonGroup
                            color="primary"
                            size="large"
                            value={type}
                            exclusive
                            onChange={(e, value) => {
                                setType(value);
                            }}
                        >
                            <ToggleButton value="total">All</ToggleButton>
                            <ToggleButton value="valid">Valid</ToggleButton>
                            <ToggleButton value="invalid">Invalid</ToggleButton>
                            <ToggleButton value="catchAll">Catch-All</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>

                <div>
                    <TestEmailResultsGrid
                        data={!isLoading && data ? data : { results: [], total: 0 }}
                        isLoading={isLoading}
                        sortModel={sortModel}
                        setSortModel={setSortModel}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        maximumRows={maximumRows}
                    />
                </div>
            </Paper>

            {showStats && (
                <Box mt={2}>
                    <EmailStatsCounter batchId={batchId === 'all' ? undefined : batchId} />
                </Box>
            )}
        </Box>
    );
};

export default TestEmailResultsReport;
