import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PeopleIcon from '@mui/icons-material/People';
import {
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSettings } from '../../../hooks/useSettings';
import { useUser } from '../../../hooks/useUser';

const DrawerListItem = (props: {
    path: string;
    text: string;
    icon: JSX.Element;
    onClick: () => void;
}) => {
    const { path, text, icon, onClick } = props;
    const location = useLocation();
    const theme = useTheme();

    return (
        <ListItemButton
            component={Link}
            to={path}
            selected={location.pathname === path}
            onClick={onClick}
            sx={{
                '&.Mui-selected': {
                    backgroundColor: theme.palette.action.selected,
                },
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
    );
};

type DrawerProps = {
    drawerWidth: number;
};

const ChatDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'drawerWidth',
})<DrawerProps>(({ drawerWidth }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
    },
    // display: 'flex',
    // flexDirection: 'column',
    // height: '100%',
}));

type Props = {
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
};

const DashboardDrawer = (props: Props) => {
    const { drawerOpen, setDrawerOpen } = props;
    const settings = useSettings();
    const user = useUser();
    const [drawerHeight, setDrawerHeight] = useState(0);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawerOpen(open);
    };

    useEffect(() => {
        // calculate the height of the Drawer's contents and set the height of the Drawer accordingly
        const drawerList = document.getElementById('scrollableDiv');

        if (drawerList) {
            setDrawerHeight(drawerList.offsetHeight);
        }
    }, [drawerOpen]);

    return (
        <ChatDrawer
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            // onClose={toggleDrawer(false)}
            drawerWidth={settings.ui.drawerWidth}
        >
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Valid8
                </Typography>
            </Toolbar>
            <Divider />
            <List>
                <DrawerListItem
                    path="/"
                    text="Home"
                    icon={<HomeIcon />}
                    onClick={() => setDrawerOpen(false)}
                />
                <DrawerListItem
                    path="/bulk"
                    text="Bulk Testing"
                    icon={<MarkEmailReadIcon />}
                    onClick={() => setDrawerOpen(false)}
                />
                <DrawerListItem
                    path="/report"
                    text="Reports"
                    icon={<AssessmentIcon />}
                    onClick={() => setDrawerOpen(false)}
                />
                {user.me && (user.me.role === 'admin' || user.me.role === 'moderator') && (
                    <>
                        <Divider />
                        <DrawerListItem
                            path="/users"
                            text="Users"
                            icon={<PeopleIcon />}
                            onClick={() => setDrawerOpen(false)}
                        />
                    </>
                )}
            </List>
        </ChatDrawer>
    );
};

export default DashboardDrawer;
