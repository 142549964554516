import {
    Paper,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Theme,
    styled,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import { useUser } from '../../hooks/useUser';
import useValid8Log, {
    IMachine,
    IMainTestEmailResponse,
    IWorkerDetails,
} from '../../hooks/useValid8Log';

const TableCard = (props: { children: React.ReactNode; title?: string }) => (
    <Paper variant="outlined" sx={{ mb: 2 }}>
        <Table size="small">
            {props.title && (
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                            {props.title}
                        </TableCell>
                    </TableRow>
                </TableHead>
            )}
            <TableBody>{props.children}</TableBody>
        </Table>
    </Paper>
);

const Row = (props: { title: string; value: React.ReactNode; monospace?: boolean }) => {
    const { title, value } = props;

    return (
        <TableRow>
            <TableCell>{title}</TableCell>
            <TableCell sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {props.monospace ? <code>{value}</code> : value}
            </TableCell>
        </TableRow>
    );
};

const RawLogCardMachine = (props: { machine: IMachine }) => {
    const { machine } = props;

    return (
        <TableCard title="Machine">
            <Row title="IP" value={machine.ip} monospace />
            <Row title="Hostname" value={machine.hostname} />
            <Row title="Port" value={machine.port} monospace />
        </TableCard>
    );
};

const RawLogCardWorkerDetails = (props: { worker: IWorkerDetails }) => {
    const { worker } = props;

    return (
        <TableCard title="Worker">
            <Row title="IP" value={worker.ip} monospace />
            <Row title="Hostname" value={worker.hostname} />
            <Row title="Port" value={worker.port} monospace />
        </TableCard>
    );
};

const RawLogCardResult = (props: { result: IMainTestEmailResponse }) => {
    const { result } = props;
    const theme: Theme = useTheme();

    return (
        <TableCard title="Result">
            <Row title="Job ID" value={result.jobId} monospace />
            <Row title="User ID" value={result.userId} monospace />
            <Row title="Email" value={result.email} />
            <Row title="Domain" value={result.domain} />
            <Row title="MX Exchange" value={result.mxExchange} />
            <Row
                title="Transcript"
                value={result.transcript.map((s, index: number) => (
                    <div
                        style={{
                            backgroundColor:
                                index % 2 === 0 ? theme.palette.grey[300] : theme.palette.grey[100],
                            paddingLeft: theme.spacing(0.5),
                            paddingRight: theme.spacing(0.5),
                        }}
                    >
                        {s}
                        <br />
                    </div>
                ))}
                monospace
            />
            <Row
                title="Extracted URLs"
                value={result.urls.map((s, index: number) => (
                    <div>
                        {s}
                        <br />
                    </div>
                ))}
                monospace
            />
            <Row title="Success" value={result.success ? 'Yes' : 'No'} />
            <Row title="Request Error" value={result.requestError ? 'Yes' : 'No'} />
            <Row title="Cahced Result" value={result.cachedResult ? 'Yes' : 'No'} />
            <Row
                title="Time Taken (ms)"
                value={`${result.timeTakenMS.toLocaleString()} ms`}
                monospace
            />
            <Row
                title="Time Taken Full (ms)"
                value={`${result.timeTakenFullMS.toLocaleString()} ms`}
                monospace
            />
            <Row title="Result" value={result.result} />
        </TableCard>
    );
};

const StyledTabs = styled(Tabs)(({ theme }) => ({
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'solid',
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.main,
    },
}));

interface StyledTabProps {
    label: string;
    value: number;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
);

const TabPanel = (props: { children?: React.ReactNode; value: number; index: number }) => {
    const { children, value, index } = props;

    return <>{value === index && children}</>;
};

interface Props {
    userId: string;
    jobId: string;
}

const RawLogCard = (props: Props) => {
    const user = useUser();
    const { userId, jobId } = props;
    const { data, isFetching } = useValid8Log(userId, jobId);
    const [currentTab, setCurrentTab] = useState(0);

    return (
        <>
            {user.me &&
                (user.me.role === 'admin' || user.me.role === 'moderator') &&
                (isFetching ? (
                    <Skeleton />
                ) : (
                    <>
                        {data && (
                            <>
                                <StyledTabs
                                    value={currentTab}
                                    onChange={(event, newValue) => {
                                        setCurrentTab(newValue);
                                    }}
                                    variant="fullWidth"
                                    sx={{ mb: 2 }}
                                >
                                    <StyledTab label="Table" value={0} />
                                    <StyledTab label="Raw JSON" value={1} />
                                </StyledTabs>

                                <TabPanel index={0} value={currentTab}>
                                    <TableCard title="Log Entry">
                                        <Row title="Type" value={data.type} />
                                        <Row title="Request IP" value={data.requestIp} monospace />
                                    </TableCard>
                                    <RawLogCardMachine machine={data.machine} />

                                    {data.type === 'result' && data.result && (
                                        <>
                                            <RawLogCardResult result={data.result} />
                                            {data.result.worker && (
                                                <RawLogCardWorkerDetails
                                                    worker={data.result.worker}
                                                />
                                            )}
                                        </>
                                    )}
                                </TabPanel>

                                <TabPanel index={1} value={currentTab}>
                                    <JsonView src={data} theme="a11y" />
                                </TabPanel>
                            </>
                        )}
                    </>
                ))}
        </>
    );
};

export default RawLogCard;
