import {
    EmailLookupResultStat,
    EmailLookupResultStats,
    NormalizedEmailLookupResultStats,
} from '../types/testEmail';

export const normalizeValid8EmailLookupStats = (
    stats: EmailLookupResultStats,
): NormalizedEmailLookupResultStats => {
    // Counter the total number of results
    let total = 0;

    // Counter the number of results that are valid
    let valid = 0;

    // Counter the number of results that are invalid
    let invalid = 0;

    // Counter the number of results that are catch-all
    let catchAll = 0;

    for (let i = 0; i < stats.length; i += 1) {
        const stat: EmailLookupResultStat = stats[i];

        switch (stat._id) {
            case 'valid':
                valid += stat.count;
                total += stat.count;
                break;

            case 'invalid':
                invalid += stat.count;
                total += stat.count;
                break;

            case 'catch-all':
                catchAll += stat.count;
                total += stat.count;
                break;

            case 'mx_not_found':
                invalid += stat.count;
                total += stat.count;
                break;

            case 'telnet_error':
                invalid += stat.count;
                total += stat.count;
                break;

            case 'blocked':
                invalid += stat.count;
                total += stat.count;
                break;

            case 'unknown':
                invalid += stat.count;
                total += stat.count;
                break;

            case 'no-workers':
            case 'invalid-user':
            case 'no-credit':
                break;
        }
    }

    // Return the normalized stats
    return {
        total,
        valid,
        invalid,
        catchAll,
    };
};
