import { Button, Grid, Paper, TextField } from '@mui/material';
import Joi from 'joi';
import { IUser } from '../../hooks/useUsers';

const UserDetailsBasicForm = (props: {
    user: IUser;
    formData: IUser;
    setFormData: (data: IUser) => void;
    error: Joi.ValidationError | undefined;
    handleSave: () => void;
}) => {
    const { user, formData, setFormData, error, handleSave } = props;

    return (
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="firstName"
                        label="First Name"
                        value={formData.firstName}
                        onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="lastName"
                        label="Last Name"
                        value={formData.lastName}
                        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        error={!!error?.details.find((d) => d.context && d.context.key === 'email')}
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'email')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="phone"
                        label="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        error={!!error?.details.find((d) => d.context && d.context.key === 'phone')}
                        helperText={
                            error?.details.find((d) => d.context && d.context.key === 'phone')
                                ?.message
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="contained"
                        disabled={error && error.details.length > 0}
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            setFormData(user);
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserDetailsBasicForm;
