import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import RawLogCard from './RawLogCard';

const RawLogDialog = (props: {
    userId: string;
    jobId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}) => {
    const { userId, jobId, open, setOpen } = props;

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="xl"
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Full Log</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <RawLogCard userId={userId} jobId={jobId} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RawLogDialog;
