import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { normalizeValid8EmailLookupStats } from '../libs/valid8Stats';
import { EmailLookupResultStats, NormalizedEmailLookupResultStats } from '../types/testEmail';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

/**
 * Fetches email lookup stats from the API
 * @param batchId Batch ID
 * @returns Normalized email lookup stats
 */
const useValid8EmailLookupStats = (batchId?: string) => {
    const settings = useSettings();
    const user = useUser();

    const fetchEmailLookupStats = async (): Promise<NormalizedEmailLookupResultStats> => {
        return axios
            .get(`${settings.apiBaseUrl}email/stats/summary`, {
                params: {
                    userEmail: user.me.email,
                    userApiKey: user.me.apiKey,
                    batchId,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                },
            })
            .then((response) => {
                return normalizeValid8EmailLookupStats(response.data as EmailLookupResultStats);
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    return useQuery(['emailLookupStats', user.me, batchId], fetchEmailLookupStats, {
        enabled: !user.isFetchingMe && user.me && user.me.email && user.me.apiKey ? true : false,
        refetchOnWindowFocus: false,
    });
};

export default useValid8EmailLookupStats;
