import { AccountCircle } from '@mui/icons-material';
import { Container, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import BoxCentered from '../../components/layout/BoxCentered';
import Login from './Login';
import Register from './Register';

interface TabPanelProps {
    children: React.ReactNode;
    value: number;
    index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && children}
        </div>
    );
};

const Welcome = (): JSX.Element => {
    const [tabValue, setTabValue] = useState<number>(0);

    return (
        <Container component="main" maxWidth="xs">
            <BoxCentered>
                <AccountCircle style={{ fontSize: 60 }} />
                <Typography component="h1" variant="h5">
                    Valid8
                </Typography>

                <Tabs
                    value={tabValue}
                    onChange={(e, newValue) => setTabValue(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                >
                    <Tab label="Login" />
                    <Tab label="Sign Up" />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                    <Login />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <Register />
                </TabPanel>
            </BoxCentered>
        </Container>
    );
};

export default Welcome;
