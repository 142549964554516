import { Avatar, Badge, Stack, styled, SxProps, Theme } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    paddingRight: 2,
    paddingBottom: 2,
}));

type Props = {
    avatar: string;
    avatarAlt: string;
    badge?: string | React.ReactNode;
    badgeAlt?: string;
    badgeOverlap?: 'rectangular' | 'circular' | undefined;
    sx?: SxProps<Theme> | undefined;
};

export const BadgeAvatars = (props: Props) => {
    return (
        <Stack direction="row" spacing={2}>
            <Badge
                overlap={props.badgeOverlap}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    props.badge && typeof props.badge === 'string' ? (
                        <SmallAvatar alt={props.badgeAlt} src={props.badge} />
                    ) : (
                        props.badge
                    )
                }
            >
                <Avatar alt={props.avatarAlt} src={props.avatar} sx={props.sx} />
            </Badge>
        </Stack>
    );
};

export default BadgeAvatars;
