import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useTheme } from '@mui/material';

export const arrayEmailLookupResult = [
    'valid',
    'invalid',
    'catch-all',
    'mx_not_found',
    'telnet_error',
    'blocked',
    'unknown',
    'no-workers',
    'invalid-user',
    'no-credit',
    'server-error',
] as const;
export type WorkerEmailLookupResult = (typeof arrayEmailLookupResult)[number];

export interface EmailTestResult {
    jobId: string;
    userId: string;
    result: WorkerEmailLookupResult;
    message: string;
    email: string;
    availableCredit: number;
}

export interface EmailLookupResultStat {
    _id: WorkerEmailLookupResult;
    count: number;
}

export type EmailLookupResultStats = EmailLookupResultStat[];

export interface EmailTestResultsHistory {
    results: EmailTestResult[];
    total: number;
}

export const arrayNormalizedEmailLookupResult = ['total', 'valid', 'invalid', 'catchAll'];
export type NormalizedEmailLookupResult = (typeof arrayNormalizedEmailLookupResult)[number];

export type NormalizedEmailLookupResultStats = {
    [key in NormalizedEmailLookupResult]: number;
};

export const emailLookupResultToHumanReadable = (result: WorkerEmailLookupResult): string => {
    switch (result) {
        case 'valid':
            return 'Valid';

        case 'invalid':
            return 'Invalid';

        case 'catch-all':
            return 'Catch-All';

        case 'mx_not_found':
            return 'MX Not Found';

        case 'telnet_error':
            // return 'Telnet Error';
            return 'Invalid';

        case 'blocked':
            return 'Blocked';

        case 'unknown':
            return 'Unknown';

        case 'no-workers':
            return 'Server Resources Exhausted';

        case 'invalid-user':
            return 'Invalid User';

        case 'no-credit':
            return 'No Credit Available';

        case 'server-error':
            return 'Server Error';
    }
};

export const EmailLookupResultToMaterialColor = (result: WorkerEmailLookupResult): string => {
    const theme = useTheme();

    switch (result) {
        case 'valid':
            return theme.palette.success.light;

        case 'invalid':
            return theme.palette.error.main;

        case 'catch-all':
            return theme.palette.warning.main;

        case 'mx_not_found':
            return theme.palette.error.main;

        case 'telnet_error':
            return theme.palette.error.main;

        case 'blocked':
            return theme.palette.error.main;

        case 'unknown':
            return theme.palette.warning.main;

        case 'no-workers':
            return theme.palette.warning.main;

        case 'invalid-user':
            return theme.palette.error.main;

        case 'no-credit':
            return theme.palette.warning.main;

        case 'server-error':
            return theme.palette.error.main;
    }
};

export const EmailLookupResultToMaterialIcon = ({
    result,
}: {
    result: WorkerEmailLookupResult;
}): JSX.Element => {
    const color = EmailLookupResultToMaterialColor(result);

    switch (result) {
        case 'valid':
            return <VerifiedIcon htmlColor={color} />;

        case 'invalid':
            return <CloseIcon htmlColor={color} />;

        case 'catch-all':
            return <CloseIcon htmlColor={color} />;

        case 'mx_not_found':
            return <CloseIcon htmlColor={color} />;

        case 'telnet_error':
            return <CloseIcon htmlColor={color} />;

        case 'blocked':
            return <CloseIcon htmlColor={color} />;

        case 'unknown':
            return <QuestionMarkIcon htmlColor={color} />;

        case 'no-workers':
            return <CloudOffIcon htmlColor={color} />;

        case 'invalid-user':
            return <QuestionMarkIcon htmlColor={color} />;

        case 'no-credit':
            return <AttachMoneyIcon htmlColor={color} />;

        case 'server-error':
            return <CloudOffIcon htmlColor={color} />;
    }
};
