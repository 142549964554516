import { Fade, Grid, LinearProgress, Theme, Typography, useTheme } from '@mui/material';

const normalise = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

const LinearProgressWithLabel = (props: { value: number; max: number; buffer: number }) => {
    const theme: Theme = useTheme();

    return (
        <Fade in>
            <Grid
                container
                alignItems={'center'}
                justifyContent={'center'}
                spacing={2}
                sx={{ marginBottom: theme.spacing(2) }}
            >
                <Grid item xs>
                    <LinearProgress
                        variant="buffer"
                        value={normalise(props.value, 0, props.max)}
                        valueBuffer={normalise(props.buffer, 0, props.max)}
                    />
                </Grid>
                <Grid item xs="auto">
                    <Typography variant="body2">
                        {props.value}/{props.max}
                    </Typography>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default LinearProgressWithLabel;
