import ClearIcon from '@mui/icons-material/Clear';
import {
    Button,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { IAddress } from '../../hooks/useUsers';

const UserDetailsAddresses = (props: {
    addresses: IAddress[] | undefined;
    handleShowAddAddressForm: () => void;
    handleDeleteAddress: (addressId: string) => void;
}) => {
    const { addresses, handleShowAddAddressForm, handleDeleteAddress } = props;

    return (
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">Addresses</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Street 1</TableCell>
                                <TableCell>Street 2</TableCell>
                                <TableCell>City</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell>Zip</TableCell>
                                <TableCell>Country</TableCell>
                                <TableCell width={30}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {addresses &&
                                addresses.map((address, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{address.street1}</TableCell>
                                        <TableCell>{address.street2}</TableCell>
                                        <TableCell>{address.city}</TableCell>
                                        <TableCell>{address.state}</TableCell>
                                        <TableCell>{address.zipCode}</TableCell>
                                        <TableCell>{address.country}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    onClick={() => {
                                                        handleDeleteAddress(address._id!);
                                                    }}
                                                >
                                                    <ClearIcon fontSize="medium" color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleShowAddAddressForm();
                        }}
                    >
                        Add Address
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default UserDetailsAddresses;
