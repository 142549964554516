import { Box, styled } from '@mui/material';

const BoxCentered = styled(Box)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

export default BoxCentered;
