import { GridSortDirection } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EmailTestResultsHistory, NormalizedEmailLookupResult } from '../types/testEmail';
import { useSettings } from './useSettings';
import { useUser } from './useUser';

/**
 * Fetches email lookup stats from the API
 * @param batchId Batch ID
 * @returns Normalized email lookup stats
 */
const useValid8History = (
    type: NormalizedEmailLookupResult,
    limit: number,
    skip: number,
    sortBy?: string,
    sortOrder?: GridSortDirection,
    batchId?: string,
) => {
    const settings = useSettings();
    const user = useUser();

    const fetchEmailLookupHistory = async (): Promise<EmailTestResultsHistory> => {
        return axios
            .get(`${settings.apiBaseUrl}users/${user.me.email}/history`, {
                params: {
                    type,
                    limit,
                    skip,
                    sortBy,
                    sortOrder,
                    batchId,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': settings.apiKey,
                    Authorization: `Bearer ${user.token}`,
                },
            })
            .then((response) => {
                return response.data as EmailTestResultsHistory;
            })
            .catch((error) => {
                throw new Error(error);
            });
    };

    return useQuery(
        ['emailLookupHistory', user.me, type, limit, skip, sortBy, sortOrder, batchId],
        fetchEmailLookupHistory,
        {
            enabled: !user.isLoggingIn && user.token && user.me && user.me.email ? true : false,
            refetchOnWindowFocus: false,
        },
    );
};

export default useValid8History;
