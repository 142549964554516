/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Paper, Theme, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import LinearProgressWithLabel from '../../LinearProgressWithLabel';
import {
    EmailLookupResultToMaterialColor,
    EmailLookupResultToMaterialIcon,
    WorkerEmailLookupResult,
    emailLookupResultToHumanReadable,
} from '../../types/testEmail';

const columns = (columnHeaders: string[], theme: Theme): GridColDef[] => {
    const columns: GridColDef[] = [];

    columnHeaders.forEach((columnHeader: string) => {
        if (columnHeader === 'Result') {
            columns.push({
                field: columnHeader,
                headerName: columnHeader,
                width: 160,
                renderCell: (params: GridRenderCellParams) => (
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid
                            item
                            xs
                            sx={{
                                color: EmailLookupResultToMaterialColor(
                                    params.value as WorkerEmailLookupResult,
                                ),
                            }}
                        >
                            {emailLookupResultToHumanReadable(params.value)}
                        </Grid>
                        <Grid item xs="auto">
                            <EmailLookupResultToMaterialIcon
                                result={params.value as WorkerEmailLookupResult}
                            />
                        </Grid>
                    </Grid>
                ),
                sortComparator: (v1: WorkerEmailLookupResult, v2: WorkerEmailLookupResult) => {
                    return emailLookupResultToHumanReadable(v1).localeCompare(
                        emailLookupResultToHumanReadable(v2),
                    );
                },
            });
        } else {
            columns.push({ field: columnHeader, headerName: columnHeader, minWidth: 100 });
        }
    });

    return columns;
};

interface CsvPreviewProps {
    isParseingCsv: boolean;
    csvParsedData: any[];
    csvColumnNames: string[];
    startProcessingRecords: () => void;
    isFetchingResults: boolean;
    loadingRows: number;
    loadingBatch: number;
    stoppingProcessingRecords: boolean;
    stopProcessingRecords: () => void;
}

const CsvPreview = (props: CsvPreviewProps) => {
    const {
        isParseingCsv,
        csvParsedData,
        csvColumnNames,
        startProcessingRecords,
        isFetchingResults,
        loadingRows,
        loadingBatch,
        stoppingProcessingRecords,
        stopProcessingRecords,
    } = props;
    const theme: Theme = useTheme();

    return (
        <Paper
            sx={{
                padding: theme.spacing(2),
                marginBottom: theme.spacing(2),
            }}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4">2. Process CSV File</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={isParseingCsv || isFetchingResults}
                        onClick={startProcessingRecords}
                        variant="contained"
                    >
                        {isParseingCsv && 'Parsing '}
                        {isFetchingResults && !isParseingCsv ? 'Processing' : 'Process'}{' '}
                        {csvParsedData.length.toLocaleString()} Rows
                    </Button>
                    {isFetchingResults && (
                        <>
                            {' '}
                            <Button
                                variant="contained"
                                color="error"
                                onClick={stopProcessingRecords}
                            >
                                {stoppingProcessingRecords ? 'Stopping' : 'Stop'}
                            </Button>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    Scroll down to download the whole table with the results as a CSV file.
                </Grid>
                {isFetchingResults && csvParsedData.length > 0 && (
                    <Grid item xs={12}>
                        <LinearProgressWithLabel
                            value={loadingRows}
                            max={csvParsedData ? csvParsedData.length : 0}
                            buffer={loadingBatch}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ overflow: 'hidden' }}>
                    <DataGridPro
                        rows={csvParsedData || []}
                        getRowId={(row) => row['__id']}
                        columns={(csvColumnNames && columns(csvColumnNames, theme)) || []}
                        autoHeight
                        disableRowSelectionOnClick
                        pageSizeOptions={[25, 50, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 25, page: 0 },
                            },
                            pinnedColumns: {
                                right: ['Result'],
                            },
                        }}
                        paginationMode="client"
                        loading={isFetchingResults}
                        pagination={true}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CsvPreview;
